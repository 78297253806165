import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import GenerateReportLink from './GenerateReportLink'

import SmartLink from 'components/SmartLink/SmartLink'

const ChocVTAM = ({ UserStore: { customer }, MissionStore: { pvRequired }, type }) => {
  const { t } = useTranslation()
  const hasExpertiseSimplify = customer.hasContract('ES')
  if (type === 'D') {
    console.log(pvRequired)
    return (
      <Fragment>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="riskConformity">{t('mission.sideNav.riskConformity')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="causesAndCircumstances">
              {t('mission.sideNav.causesAndCircumstances')}
            </SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="damageDescription">{t('mission.sideNav.damageDescription')}</SmartLink>
          )}
          <SmartLink page="calculation">{t('mission.sideNav.calculation')}</SmartLink>
          <SmartLink page="conclusion">{t('mission.sideNav.conclusion')}</SmartLink>
          {!hasExpertiseSimplify && pvRequired && (
            <SmartLink page="pv">{t('mission.sideNav.pv')}</SmartLink>
          )}
        </div>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleReport')}</h5>
        <div className="nav flex-column nav-pills">
          <GenerateReportLink />
        </div>
      </Fragment>
    )
  }
  if (type === 'M') {
    return (
      <Fragment>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="riskConformity">{t('mission.sideNav.riskConformity')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="causesAndCircumstances">
              {t('mission.sideNav.causesAndCircumstances')}
            </SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="damageDescription">{t('mission.sideNav.damageDescription')}</SmartLink>
          )}
          <SmartLink page="calculation">{t('mission.sideNav.calculation')}</SmartLink>
          <SmartLink page="conclusion">{t('mission.sideNav.conclusion')}</SmartLink>
          {!hasExpertiseSimplify && pvRequired && (
            <SmartLink page="pv">{t('mission.sideNav.pv')}</SmartLink>
          )}
        </div>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleReport')}</h5>
        <div className="nav flex-column nav-pills">
          <GenerateReportLink />
        </div>
      </Fragment>
    )
  }
}

export default inject('UserStore', 'MissionStore')(observer(ChocVTAM))
