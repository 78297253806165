import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { path, dissoc, isNil } from 'ramda'

import './CreatePackage.css'
import CardLayout from 'components/CardLayout/CardLayout'
import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import TextArea from 'components/Forms/TextArea'
import SupportingDocumentLite from 'pages/Mission/pages/Calculation/SupportingDocumentLite'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import Label from 'components/Label/Label'
import SelectInvolvedParty from 'pages/Mission/pages/Calculation/SelectInvolvedParty'
import InputNumber from 'components/InputNumber/InputNumber'
import InputPrice from 'components/InputPrice/InputPrice'
import Input from 'components/Forms/Input'
import CustomItemCtrl from 'stores/Mission/view/CustomItemCtrl'
import CustomItemForm from 'pages/Mission/pages/Calculation/DetailPackage/CustomItemForm'
import Item from 'pages/Mission/pages/Calculation/DetailPackage/Item'
import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import {
  LabelObsolescence,
  LabelObsolescenceReturn,
  LabelVAT,
} from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import { euro } from 'utils/currency'
import Button from 'components/Button/Button'

const getRoomTypeByValue = (options, value) => {
  if (value === 'other') return { label: 'Autre', key: 'other' }

  const option = options.find(room => room.value === value)
  if (option) option.key = option.value
  return option ? dissoc('value', option) : null
}

class CreatePackage extends React.Component {
  goToCart = () => {
    const { id, type } = this.props.match.params
    this.props.history.push(`/${type}/${id}/calculation/listPackage`)
  }

  onChange = e => {
    if (e.target.name === 'roomType') {
      e.target.value = getRoomTypeByValue(
        SearchPackageCtrl.missionRoomsOptionsWithAreaSize,
        e.target.value,
      )
    } else if (e.target.name === 'quantity') {
      e.target.value = e.target.value !== '' ? parseInt(e.target.value, 10) : ''
    }

    if (e.target.name === 'overrideLabel') {
      CreatePackageCtrl.packageData.setRoomTypeLabel(e.target.value)
    } else {
      CreatePackageCtrl.packageData.setProperty(e.target.name, e.target.value)
    }
  }
  addPackage = () => {
    const { id, type } = this.props.match.params
    this.props.CartStore.addCustomPackage(CreatePackageCtrl.packageData)
    this.props.history.push(`/${type}/${id}/calculation/listPackage`)
  }
  addSupportingDocument = id => CreatePackageCtrl.packageData.addSupportingDocument(id)

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      t,
      match: {
        path: url,
        params: { id, type },
      },
      CartStore: { isVentilationIPAvailable },
    } = this.props
    const { packageData } = CreatePackageCtrl
    const last = url.split('/').pop()
    const outdoorCondition = last === 'createOutdoorPackage'
    const { setNewCustomItem, customItem } = CustomItemCtrl

    if (packageData === null) {
      return <Redirect to={`/${type}/${id}/calculation/listPackage`} />
    }

    const packageTypeOptions = [
      { label: t('mission.calculation.cart.titleProperty'), value: 'DAT_PROP' },
    ]
    let roomOptions = outdoorCondition
      ? SearchFurnitureCtrl.roomsForSelect.filter(room => room.value !== 'noFilter')
      : SearchPackageCtrl.missionRoomsOptionsWithAreaSize

    if (!outdoorCondition) {
      packageTypeOptions.push({
        label: t('mission.calculation.cart.titleEmbellishment'),
        value: 'DAT_EMB',
      })
      roomOptions = [
        ...roomOptions,
        {
          label: t('mission.calculation.createPackage.roomTypeOther'),
          value: 'other',
        },
      ]
    }
    const room = path(['roomType', 'key'], packageData)

    return (
      <CardLayout className="add-package">
        <div className="create-package container pl-0 pr-0">
          <div className="row no-gutters">
            <div className="col-10 col-sm-10 col-md-9">
              <Breadcrumb />
            </div>
            <div className="col-2 col-md-3 text-right">
              <button className="btn btn-outline-primary" onClick={this.goToCart}>
                <i className="fa fa-calculator" />
                <span className="d-none d-none-sm d-none-md d-lg-inline">
                  &nbsp;
                  {t('mission.calculation.detailPackage.backToCart')}
                </span>
              </button>
            </div>
          </div>

          <div className="row no-gutters mb-3">
            <div className="col-3">
              <Label className="font-weight-bold" required>
                {t('mission.calculation.createPackage.roomType')}
              </Label>
            </div>
            <div className="col-6">
              <ResponsiveSelect
                className="select-room"
                name="roomType"
                value={path(['key'], packageData.roomType) || null}
                options={roomOptions}
                onChange={this.onChange}
                placeholder={t('common.select')}
                isSearchable
              />
            </div>
          </div>

          {packageData.roomType.key === 'other' && (
            <div className="row no-gutters mb-3">
              <div className="col-3">
                <Label className="font-weight-bold">{t('Précisez')}</Label>
              </div>
              <div className="col-6">
                <Input
                  type="text"
                  onChange={this.onChange}
                  className="form-control"
                  value={path(['label'], packageData.roomType.override) || null}
                  name="overrideLabel"
                  autoComplete="off"
                />
              </div>
            </div>
          )}

          <div className="row no-gutters mb-3">
            <div className="col-3">
              <span className="font-weight-bold">
                {t('mission.calculation.detailPackage.damageTypeLabel')}
              </span>
            </div>
            <div className="col-6">
              <ResponsiveSelect
                name="packageType"
                onChange={this.onChange}
                options={packageTypeOptions}
                value={packageData.packageType}
              />
            </div>
          </div>

          {isVentilationIPAvailable && (
            <div className="row no-gutters mb-3 align-items-center">
              <div className="col-3">
                <span className="font-weight-bold">
                  {t('mission.calculation.involvedParty.concerned')}
                </span>
              </div>
              <div className="col-6">
                <SelectInvolvedParty onChange={this.onChange} value={packageData.involvedParty} />
              </div>
            </div>
          )}

          <div className="row no-gutters mb-3">
            <div className="col-12">
              <label>
                {t('mission.calculation.createPackage.label')}{' '}
                <span className="text-danger">*</span>
              </label>
              <TextArea
                rows={2}
                className="form-control"
                name="label"
                value={packageData.label}
                onChange={this.onChange}
                errors={[]}
              />
            </div>
          </div>

          <div className="row no-gutters mb-3">
            <div className="col-12">
              <label>{t('mission.calculation.createPackage.description')}</label>
              <TextArea
                className="form-control"
                name="description"
                onChange={this.onChange}
                value={packageData.description}
                errors={[]}
              />
            </div>
          </div>

          <div className="list-item">
            {packageData.items.map(item => {
              if (!item.isCustom || customItem === null || customItem.cde !== item.cde)
                return <Item key={`item-${item.cde}`} itemData={item} packageData={packageData} />
              else
                return (
                  <CustomItemForm
                    priceType="custom"
                    packageData={packageData}
                    key={`item-${item.cde}`}
                  />
                )
            })}
          </div>

          {customItem && !customItem.cde && (
            <CustomItemForm priceType="custom" packageData={packageData} />
          )}
          {!customItem && (
            <div className="row no-gutters mt-3 mb-3">
              <div className="col-12">
                <Button className="btn btn-primary" onClick={() => setNewCustomItem(packageData)}>
                  <i className="fa fa-plus" /> {t('mission.calculation.createCustomItem.add')}
                </Button>
              </div>
            </div>
          )}

          <div className="row no-gutters mb-3">
            <div className="col-12 col-lg-5">
              <SupportingDocumentLite
                supportingDocuments={packageData.supportingDocuments}
                addSupportingDocument={this.addSupportingDocument}
              />
            </div>

            <div className="recap align-items-center col-12 col-lg-7">
              <div className="row row-recap no-gutters align-items-center mb-3">
                <div className="col-6">
                  <span className="recap-label mr-3">
                    {t('mission.calculation.detailPackage.quantityLabelCustom')}
                  </span>
                </div>

                <div className="col-6 text-right col-lg-6">
                  <InputNumber
                    name="quantity"
                    type="number"
                    className="input-number text-right"
                    minValue={1}
                    maxValue={999}
                    onChange={this.onChange}
                    value={packageData.quantity}
                  />
                </div>
              </div>

              <RecapRowLayout
                labelComponent={
                  <span className="recap-label mr-3">
                    {t('mission.calculation.detailPackage.unitPriceLabelCustom')}
                  </span>
                }
                priceComponent={
                  <InputPrice
                    name="overridePrice"
                    price={isNil(packageData.overridePrice) ? 0 : packageData.overridePrice}
                    onChange={this.onChange}
                  />
                }
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={
                  <span className="recap-label mr-3">
                    {t('mission.calculation.detailPackage.recapTitle')}
                  </span>
                }
                priceComponent={euro(packageData.quantityOriginalTotalPriceCatalog)}
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={<LabelVAT packageData={packageData} onChange={this.onChange} />}
                priceComponent={euro(packageData.totalVAT)}
                withoutUnit
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={
                  <span className="recap-label">
                    {t('mission.calculation.detailPackage.totalWithVAT')}
                  </span>
                }
                priceComponent={euro(packageData.totalPriceVAT)}
                withoutUnit={false}
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={
                  <LabelObsolescence
                    packageData={packageData}
                    onChange={this.onChange}
                    controller={CreatePackageCtrl}
                  />
                }
                priceComponent={<span>{euro(packageData.totalObsolescence)}</span>}
                withoutUnit
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={
                  <LabelObsolescenceReturn packageData={packageData} onChange={this.onChange} />
                }
                priceComponent={euro(packageData.totalObsolescenceReturn)}
                withoutUnit
                packageLayout={false}
              />

              <RecapRowLayout
                labelComponent={
                  <span className="recap-label">{t('mission.calculation.cart.totalWithVAT')}</span>
                }
                priceComponent={euro(packageData.finalPrice)}
                withoutUnit
                packageLayout={false}
                last
              />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-12 text-center">
              {CreatePackageCtrl.context === 'new' ? (
                <button
                  className="btn btn-primary"
                  onClick={this.addPackage}
                  disabled={packageData.label === '' || isNil(room)}
                >
                  {t('mission.calculation.createPackage.createPackageButton')}
                </button>
              ) : null}
            </div>
          </div>

          {CreatePackageCtrl.context === 'new' ? null : <LastUpdatedAt />}
        </div>
      </CardLayout>
    )
  }
}

export default compose(
  inject('CartStore'),
  withRouter,
  withTranslation(),
  observer,
)(CreatePackage)
