import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'
import classNames from 'classnames'

import './Mission.css'
import Header from 'pages/Mission/components/Header'
import PageLayout from 'components/PageLayout/PageLayout'
import SideNav from 'components/SideNav/SideNav'
import NotFound from 'pages/NotFound/NotFound'
import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'
import SupportingDocuments from 'pages/SupportingDocuments/SupportingDocuments'
import RiskConformity from 'pages/Mission/pages/RiskConformity/RiskConformity'
import CausesAndCircumstances from 'pages/Mission/pages/CausesAndCircumstances'
import Irsi from 'pages/Mission/pages/Irsi/Irsi'
import DamageDescription from 'pages/Mission/pages/DamageDescription'
import DamageDescriptionVehicle from 'pages/Mission/pages/DamageDescriptionVehicle'
import Calculation from 'pages/Mission/pages/Calculation/Calculation'
import EasyEstimation from 'pages/EasyEstimation'
import Rates from 'pages/Mission/pages/Rates/Rates'
import Report from 'pages/Mission/pages/Report/Report'
import MissionButtons from 'pages/Mission/components/MissionButtons'
import Loader from 'components/Loader/Loader'
import StatusSteps from 'components/StatusSteps/StatusSteps'
import CardLayout from 'components/CardLayout/CardLayout'
import MissionHeaderCtrl from 'stores/Mission/view/MissionHeaderCtrl'
import MissInfo from 'pages/Mission/components/MissionInformation'
import Events from 'pages/Events/Events'
import Messaging from 'pages/Messaging'
import Conclusion from 'pages/Mission/pages/Conclusion'
import Prejudice from 'pages/Mission/pages/Prejudice'
import ClaimInformation from 'pages/ClaimInformation'
import PolicyInformation from 'pages/PolicyInformation'
import Information from 'pages/MissionInformation'
import Teleexpertise from 'pages/Mission/components/Teleexpertise/Teleexpertise'
import VentilationREN from 'pages/Mission/pages/VentilationREN/Ren'
import Carousel from 'pages/Mission/components/Carousel/Carousel'
import ErrorBoundary from 'components/ErrorBoundary'
import Pv from 'pages/Mission/pages/Pv/Pv'
import WorkRequest from 'pages/Mission/pages/WorkRequest/WorkRequest'
import AgreementPrototcol from 'pages/Mission/pages/AgreementPrototcol/AgreementPrototcol'
import RequestMissionCreation from './pages/MissionCreation/RequestMissionCreation'
import BlocNote from './pages/BlocNote/BlocNote'

const Mission = ({
  MissionStore,
  MissionStore: {
    loadData,
    resetClaim,
    claim,
    loading,
    isWorkCFA,
    status,
    isIRSI,
    disposeReactions,
  },
  PrejudiceStore: { resetReady },
  CartStore: { easyEstimation },
  UserStore: { hasContractEE, hasContract },
  UiCtrl: { showOnlyCard },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { path } = useRouteMatch('/:type/:id')

  useEffect(() => {
    loadData(id)
    return () => {
      resetClaim()
      resetReady()
      disposeReactions()
    }
  }, [loadData, resetClaim, resetReady, id, disposeReactions])

  if (loading || isNil(claim)) return <Loader loading={t('common.loading')} />

  return (
    <div className="mission-page container pl-sm-0 pr-sm-0 pb-5 mb-5">
      <Teleexpertise />

      <div className={classNames({ 'd-none': showOnlyCard, 'd-lg-flex': !showOnlyCard }, 'row')}>
        <div className="d-none d-lg-block col-lg-3">
          <h5 className="title-side-nav">{t('mission.sideNav.title')}</h5>
        </div>

        <div className="col-12 col-lg-9">
          <Header />
        </div>
      </div>

      <div className="row no-gutters d-md-none sinister-mobile d-flex justify-content-between align-items-center mb-3">
        <div className="col-10 col-lg-7">
          {!isWorkCFA ? <StatusSteps current={status.key} /> : null}
        </div>
      </div>

      <div className="row align-items-center align-items-md-start">
        <div className={classNames({ 'd-none': showOnlyCard }, 'col-3 mb-3 mb-lg-0')}>
          <SideNav />
        </div>

        <div className="col-7 offset-5 col-md-4 offset-md-8 mb-3 d-block d-lg-none d-flex justify-content-between">
          <MissionButtons />
        </div>

        <div
          className={classNames(
            { 'col-lg-9': !showOnlyCard, showOnlyCard: showOnlyCard },
            'col-12',
          )}
        >
          {MissionHeaderCtrl.headerInfo && (
            <CardLayout className="mission-info-header mb-3">
              <MissInfo />
            </CardLayout>
          )}

          <Carousel />

          <ErrorBoundary>
            <Switch key="mission-pages">
              <Route exact path={`${path}/information`} component={Information} />
              <Route exact path={`${path}/policy`} component={PolicyInformation} />
              <Route exact path={`${path}/claim`} component={ClaimInformation} />
              <Route exact path={`${path}/partyInvolved`} component={PartyInvolved} />
              {!hasContract('FWSD') && (
                <Route exact path={`${path}/supportingDocuments`} component={SupportingDocuments} />
              )}
              {hasContract('FWSD') && (
                <Route
                  exact
                  path={`${path}/supportingDocuments`}
                  render={() => {
                    return <SupportingDocuments transfer={true} />
                  }}
                />
              )}
              <Route exact path={`${path}/riskConformity`} component={RiskConformity} />
              <Route exact path={`${path}/workRequest`} component={WorkRequest} />
              <Route exact path={`${path}/agreementProtocol`} component={AgreementPrototcol} />
              <Route
                exact
                path={`${path}/causesAndCircumstances`}
                component={CausesAndCircumstances}
              />
              <Route exact path={`${path}/irsi`} component={Irsi} />
              <Route exact path={`${path}/pv`} component={Pv} />
              <Route exact path={`${path}/damageDescription`} component={DamageDescription} />
              <Route
                exact
                path={`${path}/damageDescriptionVehicle`}
                component={DamageDescriptionVehicle}
              />
              <Route
                exact
                path={`${path}/requestMissionCreation`}
                component={RequestMissionCreation}
              />
              <Route exact path={`${path}/blocNote`} component={BlocNote} />
              <Route
                path={`${path}/calculation`}
                render={() => {
                  if (!hasContractEE || isIRSI || !easyEstimation) return <Calculation />

                  return <EasyEstimation store={MissionStore} />
                }}
              />
              <Route exact path={`${path}/ventilation`} component={VentilationREN} />
              <Route exact path={`${path}/rates`} component={Rates} />
              <Route exact path={`${path}/generateReport`} component={Report} />
              <Route exact path={`${path}/prejudice`} component={Prejudice} />
              <Route exact path={`${path}/conclusion`} component={Conclusion} />
              <Route exact path={`${path}/events`} component={Events} />
              <Route exact path={`${path}/messaging`} component={Messaging} />
              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default compose(
  PageLayout,
  inject('CommonStore', 'MissionStore', 'PrejudiceStore', 'CartStore', 'UserStore', 'UiCtrl'),
  observer,
)(Mission)
