import { observable, computed, action, autorun, decorate, toJS } from 'mobx'
import {
  isNil,
  reject,
  path,
  add,
  reduce,
  keys,
  equals,
  uniq,
  flatten,
  mergeDeepRight,
  sum,
} from 'ramda'

import { saveCart, fetchCart, getFavoritePackages } from 'services/calculation'
import UserStore from 'stores/Common/domain/UserStore'
import Package from 'stores/Mission/domain/Package'
import Furniture from 'stores/Mission/domain/Furniture'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import InstructionCtrl from 'stores/ManagerClaim/view/InstructionCtrl'
import FurnitureCatalog from 'stores/Mission/domain/FurnitureCatalog'
import RelatedCost from 'stores/Mission/domain/RelatedCost'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import MissionStore from 'stores/Mission/domain/MissionStore'
import Configuration from 'stores/Mission/domain/Configuration'
import PrejudiceStore from 'stores/Mission/domain/PrejudiceStore'
import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import FurnitureCtrl from 'stores/Mission/EasyEstimation/FurnitureCtrl'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import { roundDecimal } from 'utils/currency'
import {
  computeTotalItems,
  computeTotalByKey,
  computeUnitPrice,
  computeIdemMode,
} from 'utils/easyEstimation'
import {
  sortByRoom,
  calculTotalPackages,
  computeCatalogOutdoorPackages,
  furnitureComputationsPerCategory,
  validRCP,
  getCompensatory,
  furnituresPriceWithoutVAT,
  relatedCostsPriceWithoutVAT,
  calculTotalRelatedCost,
  furnituresPriceWithVAT,
  relatedCostsPriceWithVAT,
} from 'utils/cart'
import {
  list,
  column,
  skip,
  specialRules,
  computeImmediateSpecialRuling,
  computeDefferedSpecialRuling,
  computeTotalContractSettlement,
  initFinalPrice,
  initReturns,
  initWithOBWithoutVAT,
  resetComputations,
  irsiComputations,
  computeInstructionsRules,
} from 'utils/settlement'
import { listDeposit } from 'utils/deposit'
import {
  computeVans,
  computeObs,
  computeReturns,
  computeWithOBWithVAT,
  computeFinalPrice,
} from 'utils/descriptionPrejudices'
import PvCtrl from '../view/PvCtrl'

class CartStore {
  packages = []
  furnitures = []
  relatedCosts = []
  noGuarantees = []
  overflows = []
  configuration = new Configuration([])
  lastUpdatedAt = null
  saving = false
  loading = false
  originalPackagesLength = 0
  originalFurnituresLength = 0
  originalRelatedCostLength = 0
  originalOverflowsLength = 0
  originalNoGuaranteesLength = 0
  conclusionViewSimplified = true
  debouncing = null
  favoritePackages = []
  showSaveButton = false
  lastTypeKeyCodes = []
  lastTimeKeyDown = new Date()
  autoSaveHandler = null
  id = null
  asp = false
  originalAsp = false

  // SETTLEMENTS
  upfrontExpert = {
    immediateSettlement: 0,
    defferedSettlement: 0,
  }
  originalSettlements = null

  // PRECISIONS
  works1 = {
    defferedInvoice: 0,
    defferedResort: 0,
  }
  works2 = {
    deposit: 0,
    defferedResort: 0,
  }
  precisionsDeductible = {
    deposit: 0,
    defferedResort: 0,
  }
  originalPrecisions = null

  // TABLE INSTRUCTIONS
  instructionsSaving = false
  originalInstructions = null
  instructions = {
    selfRepair: {
      immediate: null,
      deffered: 0,
      trueTotal: 0,
    },
    ren: {
      immediate: 0,
      deffered: null,
      presented: 0,
      trueTotal: 0,
    },
    propertyAndEmbellishment: {
      immediate: null,
      deffered: null,
      presented: 0,
      trueTotal: 0,
      totalPriceImmediate: 0,
      totalPriceDeffered: 0,
    },
    furniture: {
      immediate: null,
      presented: 0,
      trueTotal: 0,
    },
    leak: {
      immediate: 0,
      deffered: 0,
      presented: 0,
      trueTotal: 0,
      totalPriceImmediate: 0,
      totalPriceDeffered: 0,
      totalPriceREN: 0,
      totalPriceIP: 0,
    },
    relatedCost: {
      immediate: null,
      deffered: null,
      presented: 0,
      totalPriceImmediate: 0,
      totalPriceDeffered: 0,
      trueTotal: 0,
    },
    negotiation: {
      immediate: 0,
    },
    franchise: {
      init: null,
      immediate: 0,
      deffered: 0,
      trueTotal: 0,
    },
    travelRCPPackage: {
      immediateOvverride: null,
      defferedOverride: null,
    },
    asp: {
      totalColumnTotal: null,
      totalAsp: null,
      totalAspGarantieQuoteInvoice: null,
      totalAspGarantieByEstimation: null,
      totalAspGarantie: null,
    },
    totalInstructionsWithoutVAT: 0,
  }

  // protect navigation to generate report
  blockNavigation = false

  deductibleOverride = {
    immediateSettlement: null,
    defferedSettlement: null,
    settlementPresented: null,
    totalPresented: null,
  }

  constructor() {
    list.forEach(type => {
      this[type.concat('Override')] = observable({
        immediateSettlement: null,
        defferedSettlement: null,
        settlementPresented: null,
        totalPresented: null,
      })
    })

    listDeposit.forEach(type => {
      this[type] = observable({
        deposit: 0,
        defferedInvoice: 0,
        defferedResort: 0,
      })
    })
  }

  get propertyPackages() {
    return this.packages.filter(
      packageData => packageData.packageType === 'DAT_PROP' && packageData.location === 'regular',
    )
  }

  get embellishmentPackages() {
    return this.packages.filter(
      packageData => packageData.packageType === 'DAT_EMB' && packageData.location === 'regular',
    )
  }

  get leakPackages() {
    return this.packages.filter(
      packageData => packageData.packageType === 'DAT_LEAK' && packageData.location === 'regular',
    )
  }

  get propertyAndembellishmentPackages() {
    return this.packages.filter(
      packageData => packageData.packageType !== 'DAT_LEAK' && packageData.location === 'regular',
    )
  }

  get packagesWithoutSelfRepair() {
    return this.packages.filter(packageData => !packageData.selfRepair)
  }

  get relatedCostPackages() {
    return this.relatedCosts.filter(relatedCost => relatedCost.packageType === 'relatedCost')
  }

  get overflowPackages() {
    return this.overflows
  }

  get noGuaranteePackages() {
    return this.noGuarantees
  }

  get outdoorPackages() {
    return [
      ...this.packages.filter(packageData => packageData.location === 'outdoor'),
      ...this.outdoorFurnitures,
    ]
  }

  get packagesWithoutOutdoor() {
    return this.packages.filter(packageData => packageData.location === 'regular')
  }

  get outdoorPackagesWithoutRoom() {
    return this.outdoorPackages.filter(outdoorPackage => outdoorPackage.roomType === null)
  }

  get outdoorPackagesWithRoom() {
    return this.outdoorPackages.filter(outdoorPackage => outdoorPackage.roomType !== null)
  }

  get regularFurnitures() {
    return this.furnitures.filter(furniture => furniture.location === 'regular')
  }

  get outdoorFurnitures() {
    return this.furnitures.filter(furniture => furniture.location === 'outdoor')
  }

  get relatedCostsAnnex() {
    return this.relatedCosts.filter(rc => rc.type === 'ANNEX')
  }

  get propertyPackagesByRoom() {
    return sortByRoom(this.propertyPackages)
  }

  get embellishmentPackagesByRoom() {
    return sortByRoom(this.embellishmentPackages)
  }

  get leakPackagesByRoom() {
    return sortByRoom(this.leakPackages)
  }

  get overrideRoomsLabel() {
    return sortByRoom(
      this.packages.filter(packageData => packageData.roomType.override !== undefined),
    )
  }

  get outdoorPackagesByRoom() {
    return sortByRoom(this.outdoorPackagesWithRoom)
  }

  get propertyTotalPriceCatalog() {
    return calculTotalPackages({
      packages: this.propertyPackages,
      key: 'finalPriceCatalog',
      selfRepair: false,
    })
  }

  get propertyTotalPriceSelfRepair() {
    return calculTotalPackages({
      packages: this.propertyPackages,
      key: 'finalPriceSelfRepair',
      selfRepair: true,
    })
  }

  get embellishmentTotalPriceCatalog() {
    return calculTotalPackages({
      packages: this.embellishmentPackages,
      key: 'finalPriceCatalog',
      selfRepair: false,
    })
  }

  get embellishmentTotalPriceSelfRepair() {
    return calculTotalPackages({
      packages: this.embellishmentPackages,
      key: 'finalPriceSelfRepair',
      selfRepair: true,
    })
  }

  get leakTotalPriceCatalog() {
    return calculTotalPackages({
      packages: this.leakPackages,
      key: 'finalPriceCatalog',
      selfRepair: false,
    })
  }

  get leakTotalPriceREN() {
    return calculTotalPackages({
      packages: this.leakPackages,
      key: 'finalPriceCatalog',
      selfRepair: false,
      ren: true,
    })
  }

  get leakTotalPriceIP() {
    return calculTotalPackages({
      packages: this.leakPackages,
      key: 'finalPriceCatalog',
      selfRepair: false,
      ren: false,
    })
  }

  get totalPriceCatalog() {
    let total = 0
    this.packages.forEach(packageData => (total += packageData.quantityTotalCatalog))
    return roundDecimal(total)
  }

  get totalPriceSelfRepair() {
    let total = 0
    this.packages.forEach(packageData => (total += packageData.quantityTotalSelfRepair))
    return roundDecimal(total)
  }

  get totalPricePackagesCatalog() {
    return roundDecimal(
      this.propertyTotalPriceCatalog +
        this.embellishmentTotalPriceCatalog +
        this.totalFurniturePrice,
    )
  }

  get totalPricePackagesSelfRepair() {
    return this.propertyTotalPriceSelfRepair + this.embellishmentTotalPriceSelfRepair
  }

  get totalPackages() {
    return this.packages.length + this.furnitures.length + this.relatedCosts.length
  }

  get totalFurniturePrice() {
    return roundDecimal(
      this.regularFurnitures.reduce((acc, furniture) => add(acc, furniture.finalPrice), 0),
    )
  }

  get totalFurniturePriceWithoutVAT() {
    return roundDecimal(
      this.regularFurnitures.reduce(
        (acc, furniture) => add(acc, furniture.finalPriceWithoutVAT),
        0,
      ),
    )
  }

  get totalOutdoorFurniturePrice() {
    return this.outdoorFurnitures.reduce((acc, furniture) => add(acc, furniture.finalPrice), 0)
  }

  get totalCatalogOR() {
    let total = 0
    this.packages.forEach(packageData => {
      if (!packageData.selfRepair) total += packageData.totalCatalogObsolescenceReturn
    })
    return total
  }

  get totalSelfRepairOR() {
    let total = 0
    this.packages.forEach(packageData => {
      if (packageData.selfRepair) total += packageData.totalSelfRepairObsolescenceReturn
    })
    return total
  }

  get totalOverflowWithVAT() {
    return reduce(
      (acc, relatedCost) => add(acc, relatedCost.priceWithVAT),
      0,
      this.overflowPackages,
    )
  }

  get totalOverflowWithoutVAT() {
    return reduce(
      (acc, relatedCost) => add(acc, relatedCost.priceWithoutVAT),
      0,
      this.overflowPackages,
    )
  }

  get totalNoGuaranteeWithVAT() {
    return reduce(
      (acc, relatedCost) => add(acc, relatedCost.priceWithVAT),
      0,
      this.noGuaranteePackages,
    )
  }

  get totalNoGuaranteeWithoutVAT() {
    return reduce(
      (acc, relatedCost) => add(acc, relatedCost.priceWithoutVAT),
      0,
      this.noGuaranteePackages,
    )
  }

  get totalOutdoorCatalogWithVAT() {
    return reduce(
      (acc, outdoorPackage) => {
        if (outdoorPackage.selfRepair === false || outdoorPackage.selfRepair === undefined)
          return add(acc, outdoorPackage.finalPrice)
        return acc
      },
      0,
      this.outdoorPackages,
    )
  }

  get totalOutdoorSelfRepairWithVAT() {
    return reduce(
      (acc, outdoorPackage) => {
        if (outdoorPackage.selfRepair === true && outdoorPackage.modalityAcquisition === undefined)
          return add(acc, outdoorPackage.finalPriceSelfRepair)
        return acc
      },
      0,
      this.outdoorPackages,
    )
  }

  get totalOutdoorCatalogWithRenWithVAT() {
    return reduce(
      (acc, outdoorPackage) => {
        if (
          (outdoorPackage.selfRepair === false || outdoorPackage.selfRepair === undefined) &&
          outdoorPackage.ren
        )
          return add(acc, outdoorPackage.finalPrice)
        return acc
      },
      0,
      this.outdoorPackages,
    )
  }

  get totalOutdoorWithVAT() {
    return roundDecimal(this.totalOutdoorCatalogWithVAT + this.totalOutdoorSelfRepairWithVAT)
  }

  get totalCatalog() {
    return (
      calculTotalPackages({
        packages: this.packagesWithoutOutdoor,
        key: 'finalPriceCatalog',
        selfRepair: false,
      }) +
      this.totalFurniturePrice +
      this.totalRelatedCostsAnnexPriceWithVAT +
      this.totalOverflowWithVAT +
      this.totalNoGuaranteeWithVAT +
      this.totalOutdoorCatalogWithVAT
    )
  }

  get totalSelfRepair() {
    return calculTotalPackages({
      packages: this.packages,
      key: 'finalPriceSelfRepair',
      selfRepair: true,
    })
  }

  get totalCartPrice() {
    return this.totalCatalog + this.totalSelfRepair
  }

  get totalRelatedCostsAnnexPriceWithoutVAT() {
    return this.relatedCostsAnnex.reduce((acc, rc) => add(acc, rc.priceWithoutVAT), 0)
  }

  get totalRelatedCostsAnnexPriceWithVAT() {
    return this.relatedCostsAnnex.reduce((acc, rc) => add(acc, rc.priceWithVAT), 0)
  }

  get cartAsJson() {
    const cart = {
      packages: {},
      furnitures: {},
      relatedCosts: {},
      overflows: {},
      noGuarantees: {},
      roomCoveringPackages: [],
      configuration: this.configuration,
      asp: this.asp,
      optionsAndOtherPrejudices: PrejudiceStore.asJson,
    }

    this.packages.forEach(packageData => {
      cart.packages[packageData.id] = packageData.asJson
    })
    this.furnitures.forEach(furniture => {
      cart.furnitures[furniture.id] = furniture.asJson
    })
    this.relatedCosts.forEach(relatedCost => {
      cart.relatedCosts[relatedCost.id] = relatedCost.asJson
    })
    this.overflows.forEach(overflow => {
      cart.overflows[overflow.id] = overflow.asJson
    })
    this.noGuarantees.forEach(noGuarantee => {
      cart.noGuarantees[noGuarantee.id] = noGuarantee.asJson
    })

    const lastVisitDate = path(['form', 'asJsonWithKey', 'lastVisitDate'], ConclusionCtrl)
    const remainderForInsured = path(
      ['form', 'asJsonWithKey', 'remainderForInsured'],
      ConclusionCtrl,
    )

    cart.conclusion = {
      ...path(
        ['form', 'asJsonWithKey'],
        UserStore.isClaimManager ? InstructionCtrl : ConclusionCtrl,
      ),
      lastVisitDate: typeof lastVisitDate === 'string' ? new Date(lastVisitDate) : lastVisitDate,
      workAmount: this.workAmount,
      remainderForInsured: isNil(remainderForInsured)
        ? this.remainderForInsured
        : remainderForInsured,
    }

    this.rcp.forEach(rcpData => {
      const packages = this.packages.filter(rcpPackage => rcpPackage.groupId === rcpData.groupId)
      const totalItems = computeTotalItems(packages, rcpData)
      const totalWithoutVAT = computeTotalByKey(packages, 'finalPriceWithoutVAT')
      const totalWithVAT = computeTotalByKey(packages, 'finalPrice')
      const unitPrice = computeUnitPrice(packages, rcpData.part, rcpData.area, rcpData.quantity)
      const indemMode = computeIdemMode(packages)

      cart.roomCoveringPackages.push({
        ...rcpData,
        totalItems,
        totalWithoutVAT,
        totalWithVAT,
        unitPrice,
        indemMode,
      })
    })

    return cart
  }

  get totalsFromCart() {
    return {
      catalog: {
        // EMBELLISHMENT
        totalEmbellishmentWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'quantityOriginalTotalPriceCatalog',
          selfRepair: false,
        }),
        totalEmbellishmentWithVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalPriceCatalogWithVAT',
          selfRepair: false,
        }),
        totalEmbellishmentObsolescence: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalPriceCatalogObsolescence',
          selfRepair: false,
        }),
        totalEmbellishmentOR: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalObsolescenceReturn',
          selfRepair: false,
        }),
        totalEmbellishmentFinalPrice: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'finalPriceCatalog',
          selfRepair: false,
        }),
        // new computations
        totalEmbellishmentObslescenceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalPriceCatalogObsolescenceWithoutVAT',
          selfRepair: false,
        }),
        totalEmbellishmentORWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalCatalogORWithoutVAT',
          selfRepair: false,
        }),
        totalEmbellishmentFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'finalPriceCatalogWithoutVAT',
          selfRepair: false,
        }),

        // PROPERTY
        totalPropertyWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'quantityOriginalTotalPriceCatalog',
          selfRepair: false,
        }),
        totalPropertyWithVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalPriceCatalogWithVAT',
          selfRepair: false,
        }),
        totalPropertyObsolescence: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalPriceCatalogObsolescence',
          selfRepair: false,
        }),
        totalPropertyOR: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalObsolescenceReturn',
          selfRepair: false,
        }),
        totalPropertyFinalPrice: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'finalPriceCatalog',
          selfRepair: false,
        }),
        // new computations
        totalPropertyObslescenceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalPriceCatalogObsolescenceWithoutVAT',
          selfRepair: false,
        }),
        totalPropertyORWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalCatalogORWithoutVAT',
          selfRepair: false,
        }),
        totalPropertyFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'finalPriceCatalogWithoutVAT',
          selfRepair: false,
        }),

        // FURNITURE
        totalFurnitureWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.regularFurnitures,
          key: 'price',
          selfRepair: undefined,
        }),
        totalFurnitureWithVAT: calculTotalPackages({
          packages: this.regularFurnitures,
          key: 'totalPriceVAT',
          selfRepair: undefined,
        }),
        totalFurnitureObsolescence: calculTotalPackages({
          packages: this.regularFurnitures,
          key: 'totalObsolescence',
          selfRepair: undefined,
        }),
        totalFurnitureOR: calculTotalPackages({
          packages: this.regularFurnitures,
          key: 'totalObsolescenceReturn',
          selfRepair: undefined,
        }),
        totalFurnitureFinalPrice: this.totalFurniturePrice,
        totalFurnitureFinalPriceWithoutVAT: this.totalFurniturePriceWithoutVAT,

        // FURNITURE PER CATEGORY
        totalsFurnituresPerCategory: this.furnituresPerCategory,

        // OUTDOOR
        totalOutdoorWithoutObsolescenceWithoutVAT: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'price',
          packageKey: 'quantityOriginalTotalPriceCatalog',
        }),
        totalOutdoorWithVAT: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'totalPriceVAT',
          packageKey: 'totalPriceCatalogWithVAT',
        }),
        totalOutdoorObsolescence: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'totalObsolescence',
          packageKey: 'totalPriceCatalogObsolescence',
        }),
        totalOutdoorOR: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'totalObsolescenceReturn',
          packageKey: 'totalObsolescenceReturn',
        }),
        totalOutdoorFinalPrice: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'finalPrice',
          packageKey: 'finalPriceCatalog',
        }),
        // new computations
        totalOutdoorObslescenceWithoutVAT: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'obsWithoutVAT',
          packageKey: 'totalPriceCatalogObsolescenceWithoutVAT',
        }),
        totalOutdoorORWithoutVAT: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'ORWithoutVAT',
          packageKey: 'totalCatalogORWithoutVAT',
        }),
        totalOutdoorFinalPriceWithoutVAT: computeCatalogOutdoorPackages({
          outdoorPackages: this.outdoorPackages,
          furnitureKey: 'finalPriceWithoutVAT',
          packageKey: 'finalPriceCatalogWithoutVAT',
        }),

        // LEAK
        totalLeakWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.leakPackages,
          key: 'quantityOriginalTotalPriceCatalog',
          selfRepair: false,
        }),
        totalLeakWithVAT: calculTotalPackages({
          packages: this.leakPackages,
          key: 'totalPriceCatalogWithVAT',
          selfRepair: false,
        }),
        totalLeakFinalPrice: calculTotalPackages({
          packages: this.leakPackages,
          key: 'finalPriceCatalog',
          selfRepair: false,
        }),
        totalLeakFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.leakPackages,
          key: 'finalPriceCatalogWithoutVAT',
          selfRepair: false,
        }),

        // TOTALS
        totalOR:
          this.totalCatalogOR +
          calculTotalPackages({
            packages: this.regularFurnitures,
            key: 'totalObsolescenceReturn',
            selfRepair: undefined,
          }),
        totalCatalogWithVATWithObsolescence: this.totalCatalog,
        relatedCostTotalPriceWithVAT: this.totalRelatedCostsAnnexPriceWithVAT,
        relatedCostTotalPriceWithoutVAT: this.totalRelatedCostsAnnexPriceWithoutVAT,
        overflowTotalPriceWithVAT: this.totalOverflowWithVAT,
        overflowTotalPriceWithoutVAT: this.totalOverflowWithoutVAT,
        noGuaranteeTotalPriceWithVAT: this.totalNoGuaranteeWithVAT,
        noGuaranteeTotalPriceWithoutVAT: this.totalNoGuaranteeWithoutVAT,
      },
      selfRepair: {
        // EMBELLISHMENT
        totalEmbellishmentWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'quantityOriginalTotalPriceSelfRepair',
          selfRepair: true,
        }),
        totalEmbellishmentWithVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalPriceSelfRepairWithVAT',
          selfRepair: true,
        }),
        totalEmbellishmentObsolescence: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalSelfRepairObsolescence',
          selfRepair: true,
        }),
        totalEmbellishmentOR: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalObsolescenceReturn',
          selfRepair: true,
        }),
        totalEmbellishmentFinalPrice: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'finalPriceSelfRepair',
          selfRepair: true,
        }),
        // new computations
        totalEmbellishmentObslescenceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalPriceSelfRepairObsolescenceWithoutVAT',
          selfRepair: true,
        }),
        totalEmbellishmentORWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'totalSelfRepairORWithoutVAT',
          selfRepair: true,
        }),
        totalEmbellishmentFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.embellishmentPackages,
          key: 'finalPriceSelfRepairWithoutVAT',
          selfRepair: true,
        }),

        // PROPERTY
        totalPropertyWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'quantityOriginalTotalPriceSelfRepair',
          selfRepair: true,
        }),
        totalPropertyWithVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalPriceSelfRepairWithVAT',
          selfRepair: true,
        }),
        totalPropertyObsolescence: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalSelfRepairObsolescence',
          selfRepair: true,
        }),
        totalPropertyOR: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalObsolescenceReturn',
          selfRepair: true,
        }),
        totalPropertyFinalPrice: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'finalPriceSelfRepair',
          selfRepair: true,
        }),
        // new computations
        totalPropertyObslescenceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalPriceSelfRepairObsolescenceWithoutVAT',
          selfRepair: true,
        }),
        totalPropertyORWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'totalSelfRepairORWithoutVAT',
          selfRepair: true,
        }),
        totalPropertyFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.propertyPackages,
          key: 'finalPriceSelfRepairWithoutVAT',
          selfRepair: true,
        }),

        // OUTDOOR
        totalOutdoorWithoutObsolescenceWithoutVAT: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'quantityOriginalTotalPriceSelfRepair',
          selfRepair: true,
        }),
        totalOutdoorWithVAT: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'totalPriceSelfRepairWithVAT',
          selfRepair: true,
        }),
        totalOutdoorObsolescence: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'totalSelfRepairObsolescence',
          selfRepair: true,
        }),
        totalOutdoorOR: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'totalObsolescenceReturn',
          selfRepair: true,
        }),
        totalOutdoorFinalPrice: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'finalPriceSelfRepair',
          selfRepair: true,
        }),
        // new computations
        totalOutdoorObslescenceWithoutVAT: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'totalPriceSelfRepairObsolescenceWithoutVAT',
          selfRepair: true,
        }),
        totalOutdoorORWithoutVAT: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'totalSelfRepairORWithoutVAT',
          selfRepair: true,
        }),
        totalOutdoorFinalPriceWithoutVAT: calculTotalPackages({
          packages: this.outdoorPackages,
          key: 'finalPriceSelfRepairWithoutVAT',
          selfRepair: true,
        }),

        totalOR: this.totalSelfRepairOR,
        totalSelfRepairWithVATWithObsolescence: this.totalSelfRepair,
      },

      totalCartWithVATWithObsolescence: this.totalCartPrice,
      totalCartWithVATWithObsolescenceWithFranchise: roundDecimal(
        this.totalCartPrice -
          (path(['guarantee', 'absoluteGeneralDeductible', 'amount'], MissionStore.policy)
            ? MissionStore.policy.guarantee.absoluteGeneralDeductible.amount
            : 0),
      ),
      optionsAndOtherPrejudices: PrejudiceStore.totals,
    }
  }

  // ---------------------------- SETTLEMENTS ----------------------------

  get franchiseMustBeDeducted() {
    if (ConclusionCtrl.loading) return this.initFranchiseMustBeDeducted
    return ConclusionCtrl.franchiseMustBeDeducted
  }

  get isFranchiseMustBeDeducted() {
    if (ConclusionCtrl.loading) return this.initIsFranchiseMustBeDeducted
    return ConclusionCtrl.isFranchiseMustBeDeducted
  }

  get computeWithoutVAT() {
    return MissionStore.isIME
  }

  get finalPriceHelper() {
    const { catalog, selfRepair } = this.totalsFromCart
    const finalPrice = initFinalPrice(
      catalog,
      selfRepair,
      this.totalRelatedCostsAnnexPriceWithVAT,
      this.totalRelatedCostsAnnexPriceWithoutVAT,
      PrejudiceStore.finalPrice,
      PrejudiceStore.finalPriceWithoutVAT,
      this.computeWithoutVAT,
    )
    const returns = initReturns(catalog, selfRepair, this.computeWithoutVAT)
    const withOBWithoutVAT = initWithOBWithoutVAT(
      catalog,
      selfRepair,
      returns,
      this.computeWithoutVAT,
    )

    if (MissionStore.isAffectedByOtherThirdParty) {
      resetComputations(finalPrice, returns, withOBWithoutVAT)
    } else if (MissionStore.isIRSI2) {
      if (isNil(MissionStore.affectedByExpertise)) {
        resetComputations(finalPrice, returns, withOBWithoutVAT)
      } else {
        const affectedBy = data => data.involvedParty === MissionStore.affectedByExpertise
        irsiComputations(
          finalPrice,
          returns,
          withOBWithoutVAT,
          affectedBy,
          this.propertyPackages,
          this.embellishmentPackages,
          this.regularFurnitures,
          this.leakPackages,
          this.relatedCostsAnnex,
          this.outdoorPackages,
          this.computeWithoutVAT,
        )
      }
    }

    return {
      finalPrice,
      returns,
      withOBWithoutVAT,
    }
  }

  get totalContractSettlement() {
    let deductible = 0
    // TO AVOID CHANGE DETECTION IN SETTLEMEMTS
    if (
      this.isFranchiseMustBeDeducted &&
      (this.franchiseMustBeDeducted === 'NON_RECUPERABLE' ||
        this.franchiseMustBeDeducted === 'TO_REPAY')
    ) {
      deductible = MissionStore.deductibleGuarantee
      if (!isNil(this.deductibleOverride.immediateSettlement)) {
        deductible = this.deductibleOverride.immediateSettlement
      }
    }

    return this.totalBaseSettlementColumn - deductible
  }

  get settlements() {
    const settlements = list.map(type => {
      const settlement = {}
      if (specialRules.indexOf(type) > -1) {
        const immediateSettlement = roundDecimal(
          computeImmediateSpecialRuling({
            dividend: this.dividend,
            configuration: this.configuration,
            finalPrice: this.finalPriceHelper.finalPrice[type],
            withOBWithoutVAT: this.finalPriceHelper.withOBWithoutVAT[type],
            totalContractSettlement: this.totalContractSettlement,
          }),
        )
        const defferedSettlement = roundDecimal(
          computeDefferedSpecialRuling({
            dividend: this.dividend,
            configuration: this.configuration,
            finalPrice: this.finalPriceHelper.finalPrice[type],
            withOBWithoutVAT: this.finalPriceHelper.withOBWithoutVAT[type],
            totalContractSettlement: this.totalContractSettlement,
          }),
        )

        settlement.immediateSettlement = roundDecimal(immediateSettlement)
        settlement.defferedSettlement = roundDecimal(defferedSettlement)
        settlement.totalContractSettlement = roundDecimal(
          computeTotalContractSettlement({
            finalPrice: this.finalPriceHelper.finalPrice[type],
            immediate: immediateSettlement,
            deffered: defferedSettlement,
            immediateOverride: this[type.concat('Override')].immediateSettlement,
            defferedOverride: this[type.concat('Override')].defferedSettlement,
          }),
        )
      } else if (skip.indexOf(type) === -1) {
        settlement.immediateSettlement = roundDecimal(this.finalPriceHelper.finalPrice[type])
        settlement.defferedSettlement = 0
        settlement.totalContractSettlement = roundDecimal(
          computeTotalContractSettlement({
            finalPrice: this.finalPriceHelper.finalPrice[type],
            immediate: this.finalPriceHelper.finalPrice[type],
            deffered: 0,
            immediateOverride: this[type.concat('Override')].immediateSettlement,
            defferedOverride: this[type.concat('Override')].defferedSettlement,
          }),
        )
      } else {
        settlement.immediateSettlement = 0
        settlement.defferedSettlement = 0
        settlement.totalContractSettlement = 0
      }

      return {
        ...settlement,
        name: type,
        override: this[type.concat('Override')],
      }
    })
    return settlements
  }

  get totalSettlementColumn() {
    return reduce(
      (acc1, column) => {
        acc1[column] = reduce(
          (acc2, settlement) => {
            if (column === 'settlementPresented' || column === 'totalPresented') {
              if (settlement.override[column] === null) return acc2
              return roundDecimal(acc2 + settlement.override[column])
            }
            if (settlement.override[column] !== null && settlement.override[column] !== undefined)
              return roundDecimal(acc2 + settlement.override[column])
            return roundDecimal(acc2 + settlement[column])
          },
          0,
          this.settlements,
        )
        return acc1
      },
      {},
      column,
    )
  }

  get totalBaseSettlementColumn() {
    return reduce(
      (acc, type) => {
        if (type === 'overflow' || type === 'noGuarantee') return acc
        return acc + this.finalPriceHelper.finalPrice[type]
      },
      0,
      list,
    )
  }

  get deductible() {
    if (!this.isFranchiseMustBeDeducted) {
      return {
        immediateSettlement: 0,
        defferedSettlement: 0,
        totalContractSettlement: 0,
        settlementPresented: 0,
        totalPresented: 0,
      }
    }

    const deductible = MissionStore.deductibleGuarantee
    const settlementPresented = this.franchiseMustBeDeducted === 'TO_REPAY' ? deductible : 0

    return {
      immediateSettlement: deductible,
      defferedSettlement: 0,
      totalContractSettlement: 0,
      settlementPresented,
      totalPresented: 0,
      override: this.deductibleOverride,
    }
  }

  get totalSettlements() {
    let deductible = 0
    // TO AVOID CHANGE DETECTION IN SETTLEMEMTS
    if (
      this.isFranchiseMustBeDeducted &&
      (this.franchiseMustBeDeducted === 'NON_RECUPERABLE' ||
        this.franchiseMustBeDeducted === 'TO_REPAY')
    ) {
      deductible = MissionStore.deductibleGuarantee
      if (!isNil(this.deductibleOverride.immediateSettlement)) {
        deductible = this.deductibleOverride.immediateSettlement
      }
    }

    const immediateSettlement =
      this.totalSettlementColumn !== undefined && this.upfrontExpert !== undefined
        ? this.totalSettlementColumn.immediateSettlement -
          deductible -
          this.upfrontExpert.immediateSettlement
        : 0

    const defferedSettlement =
      this.totalSettlementColumn !== undefined &&
      this.deductible !== undefined &&
      this.upfrontExpert !== undefined
        ? this.totalSettlementColumn.defferedSettlement -
          this.deductible.defferedSettlement -
          this.upfrontExpert.defferedSettlement
        : 0

    const totalContractSettlement = immediateSettlement + defferedSettlement

    let settlementPresented = this.totalSettlementColumn
      ? this.totalSettlementColumn.settlementPresented
      : 0
    if (this.isFranchiseMustBeDeducted && this.franchiseMustBeDeducted === 'TO_REPAY') {
      settlementPresented = this.totalSettlementColumn
        ? this.totalSettlementColumn.settlementPresented + deductible
        : 0
    }

    const totalPresented = this.totalSettlementColumn
      ? this.totalSettlementColumn.totalPresented
      : 0

    return {
      immediateSettlement: immediateSettlement < 0 ? 0 : immediateSettlement,
      defferedSettlement: defferedSettlement < 0 ? 0 : defferedSettlement,
      totalContractSettlement: totalContractSettlement < 0 ? 0 : totalContractSettlement,
      settlementPresented: settlementPresented < 0 ? 0 : settlementPresented,
      totalPresented: totalPresented < 0 ? 0 : totalPresented,
    }
  }

  get exposeSettlements() {
    const settlements = reduce(
      (acc, type) => {
        const settlement = this.settlements.find(settlement => settlement.name === type)
        if (settlement) acc[type] = settlement
        else acc[type] = null
        return acc
      },
      {},
      list,
    )

    return toJS(
      {
        ...settlements,
        totalColumn: this.totalSettlementColumn,
        deductible: { ...this.deductible, override: this.deductibleOverride },
        upfrontExpert: this.upfrontExpert,
        finalTotalSettlements: this.totalSettlements,
      },
      { recurseEverything: true },
    )
  }

  get settlementsChanged() {
    if (isNil(this.originalSettlements)) return false

    return !equals(
      toJS(this.originalSettlements, { recurseEverything: true }),
      toJS(this.exposeSettlements, { recurseEverything: true }),
    )
  }

  resetSettlements() {
    if (this.settlementsChanged) {
      list.forEach(type => {
        const typeOverride = type + 'Override'
        const original = path([type, 'override'], this.originalSettlements)
        if (original) {
          this[typeOverride].immediateSettlement = original.immediateSettlement
          this[typeOverride].defferedSettlement = original.defferedSettlement
          this[typeOverride].settlementPresented = original.settlementPresented
          this[typeOverride].totalPresented = original.totalPresented
        }
      })
      this.deductibleOverride = {
        immediateSettlement: null,
        defferedSettlement: null,
        settlementPresented: null,
        totalPresented: null,
      }

      this.upfrontExpert.immediateSettlement = this.originalSettlements.upfrontExpert.immediateSettlement
      this.upfrontExpert.defferedSettlement = this.originalSettlements.upfrontExpert.defferedSettlement
    }
  }
  // ---------------------------- END SETTLEMENTS ----------------------------

  // ---------------------------- PRECISIONS ----------------------------
  get totalPrecisionsRow() {
    const totals = reduce(
      (acc, type) => {
        acc[type] = this[type].deposit + this[type].defferedInvoice + this[type].defferedResort
        return acc
      },
      {},
      listDeposit,
    )
    totals.works1 = this.works1.defferedInvoice + this.works1.defferedResort
    totals.works2 = this.works2.deposit + this.works2.defferedResort
    totals.precisionsDeductible =
      this.precisionsDeductible.defferedResort + this.precisionsDeductible.deposit
    return totals
  }

  get totalPrecisionsColumn() {
    const totals = reduce(
      (acc, type) => {
        acc.deposit += this[type].deposit
        acc.defferedInvoice += this[type].defferedInvoice
        acc.defferedResort += this[type].defferedResort
        return acc
      },
      {
        deposit: 0,
        defferedInvoice: 0,
        defferedResort: 0,
      },
      listDeposit,
    )
    totals.defferedInvoice += this.works1.defferedInvoice
    totals.deposit += this.works2.deposit
    totals.defferedResort += this.works2.defferedResort + this.works1.defferedResort

    const totalDeposit = totals.deposit - this.precisionsDeductible.deposit
    const totalDefferedResort = totals.defferedResort + this.precisionsDeductible.defferedResort

    totals.deposit = totalDeposit < 0 ? 0 : totalDeposit
    totals.defferedResort = totalDefferedResort < 0 ? 0 : totalDefferedResort
    totals.totals = totals.deposit + totals.defferedInvoice + totals.defferedResort
    return totals
  }

  get precisionsMaxTotals() {
    let works1 = this.totalPackagesWithRen
    let works2 = this.totalSelfRepair
    let works3 = this.totalPackagesWithoutRen
    let furniture = this.totalFurniturePrice + this.totalOutdoorFurniturePrice
    let relatedCost = this.totalRelatedCostsAnnexPriceWithVAT

    if (MissionStore.isAffectedByOtherThirdParty) {
      works1 = 0
      works2 = 0
      works3 = 0
      furniture = 0
      relatedCost = 0
    } else if (MissionStore.isIRSI2) {
      if (isNil(MissionStore.affectedByExpertise)) {
        works1 = 0
        works2 = 0
        works3 = 0
        furniture = 0
        relatedCost = 0
      } else {
        const affectedBy = data => data.involvedParty === MissionStore.affectedByExpertise

        works1 = this.packagesWithRen
          .filter(affectedBy)
          .reduce((acc, packageData) => add(acc, packageData.finalPrice), 0)
        works2 = calculTotalPackages({
          packages: this.packages.filter(affectedBy),
          key: 'finalPriceSelfRepair',
          selfRepair: true,
        })
        works3 = this.packagesWithoutRen
          .filter(affectedBy)
          .reduce((acc, packageData) => add(acc, packageData.finalPrice), 0)
        furniture = this.regularFurnitures
          .filter(affectedBy)
          .reduce((acc, furniture) => add(acc, furniture.finalPrice), 0)
        furniture += this.outdoorFurnitures
          .filter(affectedBy)
          .reduce((acc, furniture) => add(acc, furniture.finalPrice), 0)
        relatedCost = this.relatedCostsAnnex
          .filter(affectedBy)
          .reduce((acc, rcAnnex) => add(acc, rcAnnex.priceWithVAT), 0)
      }
    }

    return {
      works1,
      works2,
      works3,
      furniture,
      otherGuarantee: PrejudiceStore.finalPrice + relatedCost,
    }
  }

  get exposePrecisions() {
    const expose = reduce(
      (acc, type) => {
        acc[type] = {
          deposit: this[type].deposit,
          defferedInvoice: this[type].defferedInvoice,
          defferedResort: this[type].defferedResort,
          totalRow: this.totalPrecisionsRow[type],
          maxTotal: this.precisionsMaxTotals[type],
        }
        return acc
      },
      {},
      listDeposit,
    )

    expose.works1 = {
      defferedInvoice: this.works1.defferedInvoice,
      defferedResort: this.works1.defferedResort,
      totalRow: this.totalPrecisionsRow.works1,
      maxTotal: this.precisionsMaxTotals.works1,
    }
    expose.works2 = {
      deposit: this.works2.deposit,
      defferedResort: this.works2.defferedResort,
      totalRow: this.totalPrecisionsRow.works2,
      maxTotal: this.precisionsMaxTotals.works2,
    }

    expose.deductible = {
      deposit: this.precisionsDeductible.deposit,
      defferedResort: this.precisionsDeductible.defferedResort,
    }
    expose.totalsColumn = this.totalPrecisionsColumn

    return expose
  }

  get precisionsChanged() {
    if (isNil(this.originalPrecisions)) return false

    return !equals(
      toJS(this.originalPrecisions, { recurseEverything: true }),
      toJS(this.exposePrecisions, { recurseEverything: true }),
    )
  }

  get checkConclusionSimplified() {
    const packagesRen = []
    this.packagesWithoutSelfRepair.forEach(packageData => {
      if (!packageData.ren) {
        packagesRen.push(packageData.companyName)
      }
    })

    // SHOW SIMPLE VIEW
    if (
      packagesRen.length === 0 ||
      (packagesRen.length === this.packagesWithoutSelfRepair.length &&
        uniq(packagesRen).length === 1)
    ) {
      return true
    }

    // SHOW MULTI VIEW
    return false
  }

  get simplifiedRen() {
    return this.packagesWithoutSelfRepair.length > 0 ? this.packagesWithoutSelfRepair[0].ren : true
  }

  get simplifiedCompanyName() {
    return this.packagesWithoutSelfRepair.length > 0
      ? this.packagesWithoutSelfRepair[0].companyName
      : ''
  }

  get hasBpuPackage() {
    return this.packages.some(pack => pack.priceList)
  }

  get isSelfRepairRateAdjustable() {
    return this.packages.some(pack => pack.priceListLabel) // TODO priceType !== 'catalog'
  }

  get packagesPriceListLabels() {
    return [
      ...new Set(this.packages.filter(p => p.priceListLabel).map(p => p.priceListLabel)),
    ].join(', ')
  }

  get packagesWithRen() {
    return this.packages.filter(packageData => packageData.ren && !packageData.selfRepair)
  }

  get packagesWithoutRen() {
    return this.packages.filter(packageData => !packageData.ren && !packageData.selfRepair)
  }

  get totalPackagesWithRen() {
    return roundDecimal(
      reduce(
        (acc, packageData) => {
          return packageData.packageType !== 'DAT_LEAK' ? acc + packageData.finalPrice : acc
        },
        0,
        this.packagesWithRen,
      ),
    )
  }

  get totalPackagesWithoutRen() {
    return roundDecimal(
      reduce(
        (acc, packageData) => {
          return acc + packageData.finalPrice
        },
        0,
        this.packagesWithoutRen,
      ),
    )
  }

  resetPrecisions() {
    if (this.precisionsChanged) {
      listDeposit.forEach(type => {
        const original = path([type], this.originalPrecisions)
        if (original) {
          this[type].deposit = original.deposit
          this[type].defferedInvoice = original.defferedInvoice
          this[type].defferedResort = original.defferedResort
        }
      })

      this.works1.defferedInvoice = this.originalPrecisions.works1.defferedInvoice
      this.works1.defferedResort = this.originalPrecisions.works1.defferedResort

      this.works2.deposit = this.originalPrecisions.works2.deposit
      this.works2.defferedResort = this.originalPrecisions.works2.defferedResort

      this.precisionsDeductible.deposit = this.originalPrecisions.deductible.deposit
      this.precisionsDeductible.defferedResort = this.originalPrecisions.deductible.defferedResort
    }
  }
  // ---------------------------- END PRECISIONS ----------------------------

  // ---------------------------- DYNFORM ----------------------------
  get workAmount() {
    const total = reduce(
      (acc, packageData) => add(acc, packageData.totalPriceVAT),
      0,
      this.packagesWithRen,
    )

    return total
  }

  get totalRemainderForInsured() {
    const total = reduce(
      (acc, packageData) => {
        return acc + packageData.totalForInsuredWithVAT
      },
      0,
      this.packagesWithRen,
    )
    return total + MissionStore.deductibleGuarantee
  }

  // ---------------------------- VENTILATION DESCRIPTION PREJUDICE BY IP ----------------------------
  get isVentilationIPAvailable() {
    if (MissionStore.isIME) return false

    return MissionStore.isIRSI2
  }

  get uniqIPs() {
    if (!MissionStore.isIRSI2) return []
    const mapping = data => data.involvedParty
    const allIPs = [
      ...this.propertyPackages.map(mapping),
      ...this.embellishmentPackages.map(mapping),
      ...this.leakPackages.map(mapping),
      ...this.relatedCosts.map(mapping),
      ...this.outdoorPackages.map(mapping),
      ...this.furnitures.map(mapping),
    ]

    return uniq(allIPs).filter(data => !isNil(data))
  }

  get packagesWithIP() {
    let isIP = data => data.involvedParty && data.involvedParty !== 'notVentilated'

    if (MissionStore.isIRSI2) {
      isIP = data => MissionStore.IRSIInvolvedParty.indexOf(data.involvedParty) > -1
    }

    return {
      properties: this.propertyPackages.filter(isIP),
      embellishments: this.embellishmentPackages.filter(isIP),
      leaks: this.leakPackages.filter(isIP),
      relatedCosts: this.relatedCosts.filter(isIP),
      outdoors: this.outdoorPackages.filter(isIP),
    }
  }

  get packagesWithoutIP() {
    const isNotIP = data => data.involvedParty === null || data.involvedParty === 'notVentilated'
    return {
      properties: this.propertyPackages.filter(isNotIP),
      embellishments: this.embellishmentPackages.filter(isNotIP),
      leaks: this.leakPackages.filter(isNotIP),
      relatedCosts: this.relatedCosts.filter(isNotIP),
      outdoors: this.outdoorPackages.filter(isNotIP),
    }
  }

  get showComputationWithoutIP() {
    return (
      this.packagesWithoutIP.properties.length > 0 ||
      this.packagesWithoutIP.embellishments.length > 0 ||
      this.packagesWithoutIP.leaks.length > 0 ||
      this.packagesWithoutIP.relatedCosts.length > 0 ||
      this.packagesWithoutIP.outdoors.length > 0
    )
  }

  get isInsuredVentilated() {
    return this.listIP.indexOf(MissionStore.insuredInformationId) > -1
  }

  get listIP() {
    const IPs = []
    keys(this.packagesWithIP).forEach(packageType => {
      this.packagesWithIP[packageType].forEach(packageData => {
        if (IPs.indexOf(packageData.involvedParty) === -1) {
          IPs.push(packageData.involvedParty)
        }
      })
    })

    if (
      PrejudiceStore.ready &&
      !!PrejudiceStore.payloads.length &&
      path(['totals', 'finalPrice'], PrejudiceStore) > 0 &&
      IPs.indexOf(MissionStore.insuredInformationId) === -1
    ) {
      IPs.unshift(MissionStore.insuredInformationId)
    }
    return IPs
  }

  get packagesSortedByIP() {
    const IPs = []
    this.listIP.forEach(IP => {
      const isIP = data => data.involvedParty === IP
      const IPObject = reduce(
        (acc, key) => {
          acc[key] = this.packagesWithIP[key].filter(isIP)
          return acc
        },
        { involvedParty: IP },
        keys(this.packagesWithIP),
      )

      IPs.push(IPObject)
    })
    return IPs
  }

  get computationsByIP() {
    const computationsByIp = this.packagesSortedByIP.map(IPData => {
      const vans = computeVans(IPData)
      const obs = computeObs(IPData)
      const returns = computeReturns(IPData)
      const noReturns = {
        property: obs.property - returns.property,
        embellishment: obs.embellishment - returns.embellishment,
        outdoor: obs.outdoor - returns.outdoor,
      }
      const withOBWithoutVAT = {
        property: vans.property - obs.property,
        embellishment: vans.embellishment - obs.embellishment,
        outdoor: vans.outdoor - obs.outdoor,
      }
      const withOBWithVAT = computeWithOBWithVAT(IPData)
      const finalPrice = computeFinalPrice(IPData)

      return {
        involvedPartyId: IPData.involvedParty,
        vans,
        obs,
        returns,
        noReturns,
        withOBWithoutVAT,
        withOBWithVAT,
        finalPrice,
      }
    })

    const insuredIP = computationsByIp.find(
      IP => IP.involvedPartyId === MissionStore.insuredInformationId,
    )

    if (insuredIP && PrejudiceStore.ready && PrejudiceStore.payloads.length > 0) {
      insuredIP.vans.options = PrejudiceStore.vanWithoutVAT
      insuredIP.obs.options = PrejudiceStore.realObsolescence
      insuredIP.returns.options = PrejudiceStore.guaranteedObsolescence
      insuredIP.noReturns.options = PrejudiceStore.nonGuaranteedObsolescence
      insuredIP.withOBWithoutVAT.options = PrejudiceStore.obsolescenceDeductedWithoutVAT
      insuredIP.withOBWithVAT.options = PrejudiceStore.obsolescenceDeductedWithVAT
      insuredIP.finalPrice.options = PrejudiceStore.finalPrice
    }

    return computationsByIp
  }

  get computationsWithoutIP() {
    const vans = computeVans(this.packagesWithoutIP)
    const obs = computeObs(this.packagesWithoutIP)
    const returns = computeReturns(this.packagesWithoutIP)
    const noReturns = {
      property: obs.property - returns.property,
      embellishment: obs.embellishment - returns.embellishment,
      outdoor: obs.outdoor - returns.outdoor,
    }
    const withOBWithoutVAT = {
      property: vans.property - obs.property,
      embellishment: vans.embellishment - obs.embellishment,
      outdoor: vans.outdoor - obs.outdoor,
    }
    const withOBWithVAT = computeWithOBWithVAT(this.packagesWithoutIP)
    const finalPrice = computeFinalPrice(this.packagesWithoutIP)

    return {
      involvedPartyId: 'notVentilated',
      vans,
      obs,
      returns,
      noReturns,
      withOBWithoutVAT,
      withOBWithVAT,
      finalPrice,
    }
  }

  get exposeIPPackages() {
    if (this.isVentilationIPAvailable) {
      return this.computationsByIP.concat(this.computationsWithoutIP)
    }

    return []
  }
  // ---------------------------- END VENTILATION DESCRIPTION PREJUDICE BY IP ----------------------------

  // ---------------------------- VENTILATION DESCRIPTION FURNITURE BY IP ----------------------------
  get furnituresPerCategory() {
    return furnitureComputationsPerCategory(this.regularFurnitures)
  }

  get furnituresWithIP() {
    let isIP = data => data.involvedParty

    if (MissionStore.isIRSI2) {
      isIP = data => MissionStore.IRSIInvolvedParty.indexOf(data.involvedParty) > -1
    }

    return this.regularFurnitures.filter(isIP)
  }

  get furnituresWithoutIP() {
    return this.regularFurnitures.filter(data => data.involvedParty === null)
  }

  get furnituresSortedByIP() {
    const IPs = []
    this.furnituresWithIP.forEach(furniture => {
      const IP = IPs.find(ip => ip.id === furniture.involvedParty)
      if (!IP) {
        IPs.push({
          id: furniture.involvedParty,
          furnitures: [furniture],
        })
      } else IP.furnitures.push(furniture)
    })
    return IPs
  }

  get furnituresComputationsByIP() {
    return this.furnituresSortedByIP.map(IP => {
      return {
        involvedPartyId: IP.id,
        computations: furnitureComputationsPerCategory(IP.furnitures),
      }
    })
  }

  get furnituresCompututationsWithoutIP() {
    return {
      involvedPartyId: 'notVentilated',
      computations: furnitureComputationsPerCategory(this.furnituresWithoutIP),
    }
  }

  get exposeIPFurnitures() {
    if (this.isVentilationIPAvailable) {
      return this.furnituresComputationsByIP.concat(this.furnituresCompututationsWithoutIP)
    }
    return []
  }

  // ---------------------------- END VENTILATION DESCRIPTION FURNITURE BY IP ----------------------------

  get ventilationIPsData() {
    const ventilation = []

    this.computationsByIP.forEach(prejudices => {
      const index = ventilation.findIndex(
        ventilated => ventilated.involvedPartyId === prejudices.involvedPartyId,
      )
      if (index > -1) {
        ventilation[index].prejudices = prejudices
      } else {
        ventilation.push({
          involvedPartyId: prejudices.involvedPartyId,
          prejudices: prejudices,
          furnitures: null,
        })
      }
    })

    this.furnituresComputationsByIP.forEach(furnitures => {
      const index = ventilation.findIndex(
        ventilated => ventilated.involvedPartyId === furnitures.involvedPartyId,
      )
      if (index > -1) {
        ventilation[index].furnitures = furnitures
      } else {
        ventilation.push({
          involvedPartyId: furnitures.involvedPartyId,
          furnitures: furnitures,
          prejudices: null,
        })
      }
    })

    ventilation.push({
      involvedPartyId: 'notVentilated',
      furnitures: this.furnituresCompututationsWithoutIP,
      prejudices: this.computationsWithoutIP,
    })

    return ventilation
  }

  // ---------------------------- VENTILATION MULTIPLE BY IP ----------------------------

  get RENByIP() {
    const order = []
    const RENByIP = []

    this.packagesWithoutSelfRepair.forEach(packageData => {
      const ip = RENByIP.find(ip => ip.id === packageData.involvedParty)

      if (ip) {
        ip.packages.push(packageData)
      } else {
        RENByIP.push({
          id: packageData.involvedParty,
          packages: [packageData],
        })
      }
    })

    let insuredIP = null
    let noIP = null
    RENByIP.forEach(ip => {
      if (ip.id === MissionStore.insuredInformationId) insuredIP = ip
      else if (ip.id === null) noIP = ip
      else order.push(ip)
    })
    order.unshift(insuredIP)
    order.push(noIP)
    return order.filter(ip => ip !== null)
  }

  get changedREN() {
    let changedREN = false
    for (let index = 0; index < this.packagesWithoutSelfRepair.length; index++) {
      const element = this.packagesWithoutSelfRepair[index]

      if (element.changedREN) {
        changedREN = true
        break
      }
    }

    return changedREN
  }

  // ---------------------------- END VENTILATION MULTIPLE BY IP ----------------------------

  // SDs
  get cartSD() {
    const SDs = []
    const detailSDs = {}
    const reduceSD = (type, data, acc, sd) => {
      acc[sd] = {
        type,
        data,
      }
      return acc
    }

    this.packages.forEach(packageData => {
      let packageType = 'package'
      if (packageData.priceType === 'custom' && packageData.location === 'regular')
        packageType = 'customPackage'
      else if (packageData.priceType === 'catalog' && packageData.location === 'regular')
        packageType = 'package'
      else if (packageData.priceType === 'custom' && packageData.location === 'outdoor')
        packageType = 'customOutdoorPackage'
      else if (packageData.priceType === 'custom' && packageData.location === 'regular')
        packageType = 'outdoorPackage'

      SDs.push(packageData.supportingDocuments)
      packageData.supportingDocuments.reduce(
        reduceSD.bind(this, packageType, packageData),
        detailSDs,
      )
    })

    this.furnitures.forEach(furniture => {
      const furnitureType = furniture.location === 'outdoor' ? 'outdoorFurniture' : 'furniture'

      SDs.push(furniture.supportingDocuments)
      furniture.supportingDocuments.reduce(reduceSD.bind(this, furnitureType, furniture), detailSDs)
    })

    this.relatedCosts.forEach(relatedCost => {
      SDs.push(relatedCost.supportingDocuments)
      relatedCost.supportingDocuments.reduce(
        reduceSD.bind(this, 'relatedCost', relatedCost),
        detailSDs,
      )
    })

    this.noGuarantees.forEach(noGuarantee => {
      SDs.push(noGuarantee.supportingDocuments)
      noGuarantee.supportingDocuments.reduce(
        reduceSD.bind(this, 'noGuarantee', noGuarantee),
        detailSDs,
      )
    })

    this.overflows.forEach(overflow => {
      SDs.push(overflow.supportingDocuments)
      overflow.supportingDocuments.reduce(reduceSD.bind(this, 'overflow', overflow), detailSDs)
    })

    return {
      sdIds: flatten(SDs),
      detailSDs,
    }
  }

  // ---------------------------- EASY ESTIMATION ----------------------------

  easyEstimation = true
  originalEasyEstimation = null
  rcp = []

  get selfRepairRate() {
    return path(['selfRepairRate'], this.configuration)
  }

  get isCartEmpty() {
    return (
      this.rcp.length === 0 &&
      this.packages.length === 0 &&
      this.furnitures.length === 0 &&
      this.relatedCosts.length === 0 &&
      this.noGuarantees.length === 0 &&
      this.overflows.length === 0
    )
  }

  addRCP = rcp => {
    this.rcp.push(rcp)
  }

  changeGroupIdPackages = (groupId, key, value) => {
    this.packages.forEach(data => {
      if (data.groupId === groupId) {
        data.setProperty(key, value)

        // TO OPTIMIZE ?
        if (key === 'selfRepair' && value === true) {
          data.setProperty('ren', false)
        }
      }
    })
  }

  deleteRCP = groupId => {
    this.rcp = this.rcp.filter(rcp => rcp.groupId !== groupId)
    this.packages = this.packages.filter(data => data.groupId !== groupId)
  }

  emptyCart(el = null) {
    if (el === null) {
      this.rcp = []
      this.packages = []
      this.furnitures = []
      this.relatedCosts = []
      this.noGuarantees = []
      this.overflows = []

      return
    }

    switch (el) {
      case 'rcp':
        this.rcp = []
        break
      case 'packages':
        this.packages = []
        break
      case 'furnitures':
        this.furnitures = []
        break
      case 'relatedCosts':
        this.relatedCosts = []
        break
      case 'noGuarantees':
        this.noGuarantees = []
        break
      case 'overflows':
        this.overflows = []
        break
      default:
        console.error('unkown option')
    }
  }

  deleteByRoom(roomId) {
    this.rcp = this.rcp.filter(rcp => rcp.roomId !== roomId)
    this.packages = this.packages.filter(packageData => packageData.roomType.id !== roomId)
  }
  // ---------------------------- END EASY ESTIMATION ----------------------------
  setOriginalData(key, value) {
    this[key] = toJS(this[value], { recurseEverything: true })
  }

  debounce(cb, time) {
    this.blockNavigation = true
    if (this.debouncing !== null) clearTimeout(this.debouncing)
    this.debouncing = setTimeout(
      action(() => {
        this.debouncing = null
        cb()
      }),
      time,
    )
  }

  changeAllWithoutSelfRepairPackages = (key, value) => {
    this.packagesWithoutSelfRepair.forEach(packageData => {
      packageData.setProperty(key, value)
    })
  }

  addPackage(packageData) {
    packageData.setProperty('fromList', 'cart')
    this.packages.push(packageData)
  }

  replacePackage(replaceId, packageData) {
    this.packages = this.packages.map(pkg => {
      return pkg.id === replaceId ? packageData : pkg
    })
  }

  addCustomPackage(packageData) {
    this.packages.push(packageData)
  }

  removePackage(id) {
    this.packages = reject(packageData => packageData.id === id, this.packages)
  }

  addFurniture(furniture) {
    this.furnitures.push(furniture)
  }

  removeFurniture(id) {
    this.furnitures = reject(furniture => furniture.id === id, this.furnitures)
  }

  replaceRelatedCost(replaceId, relatedCost) {
    switch (relatedCost.packageType) {
      case 'relatedCost':
        this.relatedCosts = this.relatedCosts.map(rc => {
          return rc.id === replaceId ? relatedCost : rc
        })
        break
      case 'overflow':
        this.overflows = this.overflows.map(rc => {
          return rc.id === relatedCost.id ? relatedCost : rc
        })
        break
      case 'noGuarantee':
        this.noGuarantees = this.relatedCost.map(rc => {
          return rc.id === relatedCost.id ? relatedCost : rc
        })
        break
      default:
        this.relatedCosts = this.relatedCosts.map(rc => {
          return rc.id === relatedCost.id ? relatedCost : rc
        })
    }
  }

  addRelatedCost(relatedCost) {
    switch (relatedCost.packageType) {
      case 'relatedCost':
        this.relatedCosts.push(relatedCost)
        break
      case 'overflow':
        this.overflows.push(relatedCost)
        break
      case 'noGuarantee':
        this.noGuarantees.push(relatedCost)
        break
      default:
        this.relatedCosts.push(relatedCost)
    }
  }

  removeRelatedCost(id, packageType) {
    if (packageType === 'overflow')
      this.overflows = reject(relatedCost => relatedCost.id === id, this.overflows)
    else if (packageType === 'noGuarantee')
      this.noGuarantees = reject(relatedCost => relatedCost.id === id, this.noGuarantees)
    else this.relatedCosts = reject(relatedCost => relatedCost.id === id, this.relatedCosts)
  }

  setProperty = (key, value) => {
    const path = key.split('.')
    if (path.length === 2) {
      this[path[0]][path[1]] = value
    } else {
      this[key] = value
    }
  }

  setInstructions = (key, value) => {
    const path = key.split('.')
    this.instructions[path[0]][path[1]] = value
  }

  initInstructions = () => {
    console.log('initInstructions')
    this.instructions = {
      selfRepair: {
        immediate: null,
        deffered: 0,
      },
      ren: {
        immediate: 0,
        deffered: null,
        presented: 0,
      },
      propertyAndEmbellishment: {
        immediate: null,
        deffered: null,
        presented: 0,
      },
      furniture: {
        immediate: null,
        presented: 0,
      },
      leak: {
        immediate: null,
        deffered: null,
        presented: null,
      },
      relatedCost: {
        immediate: null,
        deffered: null,
        presented: 0,
      },
      negotiation: {
        immediate: 0,
      },
      franchise: {
        init: null,
        immediate: 0,
        deffered: 0,
        trueTotal: 0,
      },
      compensationMinPrice: {
        immediate: 0,
        deffered: 0,
      },
      travelRCPPackage: {
        immediateOvverride: null,
        defferedOverride: null,
      },
      asp: {
        totalColumnTotal: null,
        totalAsp: null,
        totalAspGarantieQuoteInvoice: null,
        totalAspGarantieByEstimation: null,
        totalAspGarantie: null,
      },
      totalInstructionsWithoutVAT: 0,
    }
    this.originalInstructions = toJS(this.instructions, { recurseEverything: true })
  }

  get instructionsChanged() {
    if (isNil(this.originalInstructions)) return false
    if (this.instructionsSaving) return false

    return !equals(
      toJS(this.instructions, { recurseEverything: true }),
      toJS(this.originalInstructions, { recurseEverything: true }),
    )
  }

  updateInstructionsOrigined = () => {
    this.originalInstructions = toJS(this.instructions, { recurseEverything: true })
  }

  resetInstructionsToOrigine = () => {
    if (this.instructionsChanged) {
      this.instructions = toJS(this.originalInstructions, { recurseEverything: true })
    }
  }

  instructionsIRSIRule = () => {
    this.instructions.ren.presented = 0
    this.instructions.propertyAndEmbellishment.presented = 0
    this.instructions.furniture.presented = 0
    this.instructions.leak.presented = 0
    this.instructions.relatedCost.presented = 0
  }

  verifyValue = (overrideValue, value) => (isNil(overrideValue) ? value : overrideValue)

  get totalPropertyAndEmbellishment() {
    return this.packages.reduce((acc, packageData) => {
      if (!packageData.ren && !packageData.selfRepair && packageData.packageType !== 'DAT_LEAK')
        return acc + packageData.finalPrice
      return acc
    }, 0)
  }

  get totalWithoutObsWithoutVAT() {
    return this.packages.reduce((acc, packageData) => {
      if (!packageData.ren && !packageData.selfRepair)
        return acc + packageData.quantityOriginalTotalPriceCatalog
      return acc
    }, 0)
  }

  get totalCatalogORWithoutVAT() {
    return this.packages.reduce((acc, packageData) => {
      if (!packageData.ren && !packageData.selfRepair)
        return acc + packageData.totalCatalogORWithoutVAT
      return acc
    }, 0)
  }

  get totalWithObsWithoutVAT() {
    return this.totalWithoutObsWithoutVAT + this.totalCatalogORWithoutVAT
  }

  get propertyAndEmbellishmentRules() {
    return computeInstructionsRules(
      this.dividend,
      this.configuration,
      this.totalPropertyAndEmbellishment,
      this.totalWithObsWithoutVAT,
      this.asp,
      this.isAspInvoice,
    )
  }

  get selfRepairImmediate() {
    if (this.asp) return 0

    return this.verifyValue(this.instructions.selfRepair.immediate, this.totalSelfRepair)
  }

  get propertyAndEmbellishmentImmediate() {
    return this.verifyValue(
      this.instructions.propertyAndEmbellishment.immediate,
      this.propertyAndEmbellishmentRules.immediate,
    )
  }

  get furnitureImmediate() {
    return this.verifyValue(this.instructions.furniture.immediate, this.totalFurniturePrice)
  }

  get totalRelatedCostSelRepair() {
    return calculTotalRelatedCost({
      relatedCosts: this.relatedCostsAnnex,
      key: 'priceWithVAT',
      selfRepair: true,
      ren: false,
    })
  }

  get totalRelatedCostIP() {
    return calculTotalRelatedCost({
      relatedCosts: this.relatedCostsAnnex,
      key: 'priceWithVAT',
      selfRepair: false,
      ren: false,
    })
  }

  get totalRelatedCostREN() {
    return calculTotalRelatedCost({
      relatedCosts: this.relatedCostsAnnex,
      key: 'priceWithVAT',
      selfRepair: false,
      ren: true,
    })
  }

  get relatedCostImmediate() {
    let totalPrice = 0
    if (!UserStore.isClaimManager) {
      totalPrice = this.totalRelatedCostsAnnexPriceWithVAT
    } else {
      let relatedCostSelRepair = this.totalRelatedCostSelRepair
      let totalRelatedCostIP = this.totalRelatedCostIP
      if (this.asp && this.isAspInvoice) {
        totalPrice = 0
      } else {
        totalPrice = relatedCostSelRepair + totalRelatedCostIP / this.dividend
      }
    }

    return this.verifyValue(this.instructions.relatedCost.immediate, totalPrice)
  }

  get relatedCostDeffered() {
    let totalPrice = 0
    if (!UserStore.isClaimManager) {
      totalPrice = 0
    } else {
      let totalRelatedCostREN = this.totalRelatedCostREN
      let totalRelatedCostIP = this.totalRelatedCostIP
      if (this.asp && this.isAspInvoice) {
        totalPrice = totalRelatedCostREN + totalRelatedCostIP
      } else {
        totalPrice = totalRelatedCostREN + totalRelatedCostIP / this.dividend
      }
    }

    return this.verifyValue(this.instructions.relatedCost.deffered, totalPrice)
  }

  get totalColumnImmediate() {
    if (this.asp) {
      return sum([
        this.propertyAndEmbellishmentImmediate,
        this.furnitureImmediate,
        this.leakImmediate,
        this.relatedCostImmediate,
        this.instructions.negotiation.immediate,
        this.travelRCPPackage.immediate,
      ])
    }

    return sum([
      this.selfRepairImmediate,
      this.instructions.ren.immediate,
      this.propertyAndEmbellishmentImmediate,
      this.furnitureImmediate,
      this.leakImmediate,
      this.relatedCostImmediate,
      this.instructions.negotiation.immediate,
      this.travelRCPPackage.immediate,
      this.compensationMinPrice.immediate,
      this.compensatoryPackages.immediate,
    ])
  }

  get renDeffered() {
    return this.verifyValue(this.instructions.ren.deffered, this.totalPackagesWithRen)
  }

  get propertyAndEmbellishmentDeffered() {
    return this.verifyValue(
      this.instructions.propertyAndEmbellishment.deffered,
      this.propertyAndEmbellishmentRules.deffered,
    )
  }

  get leakDeffered() {
    let price = this.leakTotalPriceIP / this.dividend
    if (this.asp && this.isAspInvoice) {
      price = this.leakTotalPriceIP
    }

    return this.verifyValue(this.instructions.leak.deffered, sum([price, this.leakTotalPriceREN]))
  }

  get leakImmediate() {
    let price = this.leakTotalPriceIP / this.dividend
    if (this.asp && this.isAspInvoice) {
      price = 0
    }
    return this.verifyValue(this.instructions.leak.immediate, price)
  }

  get totalColumnDeffered() {
    if (this.asp) {
      return sum([
        this.propertyAndEmbellishmentDeffered,
        this.leakDeffered,
        this.relatedCostDeffered,
        this.travelRCPPackage.deffered,
      ])
    }

    return sum([
      this.instructions.selfRepair.deffered,
      this.renDeffered,
      this.propertyAndEmbellishmentDeffered,
      this.leakDeffered,
      this.relatedCostDeffered,
      this.travelRCPPackage.deffered,
      this.compensationMinPrice.deffered,
      this.compensatoryPackages.deffered,
    ])
  }

  get selfRepairTotal() {
    return this.selfRepairImmediate + this.instructions.selfRepair.deffered
  }

  get renTotal() {
    return this.instructions.ren.immediate + this.renDeffered
  }

  get leakTotal() {
    return this.leakImmediate + this.leakDeffered
  }

  get furnitureTotal() {
    return this.furnitureImmediate
  }

  get relatedCostTotal() {
    return this.relatedCostImmediate + this.relatedCostDeffered
  }

  get totalColumnTotal() {
    return sum([
      this.selfRepairTotal,
      this.renTotal,
      this.totalPropertyAndEmbellishment,
      this.furnitureTotal,
      this.leakTotal,
      this.relatedCostTotal,
      this.instructions.negotiation.immediate,
      this.travelRCPPackage.immediate,
      this.travelRCPPackage.deffered,
      this.compensationMinPrice.immediate,
      this.compensationMinPrice.deffered,
      this.compensatoryPackages.immediate,
      this.compensatoryPackages.deffered,
    ])
  }

  get totalColumnPresented() {
    return sum([
      this.instructions.ren.presented,
      this.instructions.propertyAndEmbellishment.presented,
      this.instructions.furniture.presented,
      this.instructions.leak.presented,
      this.instructions.relatedCost.presented,
    ])
  }

  instructionsPresentedRule = () => {
    this.instructions.ren.presented = this.renTotal
    this.instructions.propertyAndEmbellishment.presented = this.totalPropertyAndEmbellishment
    this.instructions.furniture.presented = this.furnitureImmediate
    this.instructions.leak.presented = this.leakTotal
    this.instructions.relatedCost.presented = this.relatedCostTotal
  }

  get instructionsAsjson() {
    const instructions = {
      ...this.instructions,
      compensatoryPackages: this.compensatoryPackages,
      compensationMinPrice: this.compensationMinPrice,
    }

    instructions.selfRepair.trueTotal = this.totalSelfRepair

    instructions.ren.trueTotal = this.totalPackagesWithRen

    instructions.propertyAndEmbellishment.totalPriceImmediate = this.propertyAndEmbellishmentImmediate
    instructions.propertyAndEmbellishment.totalPriceDeffered = this.propertyAndEmbellishmentDeffered
    instructions.propertyAndEmbellishment.trueTotal = this.totalPropertyAndEmbellishment

    instructions.furniture.trueTotal = this.totalFurniturePrice

    instructions.leak.trueTotal = this.leakTotalPriceCatalog
    instructions.leak.totalPriceREN = this.leakTotalPriceREN
    instructions.leak.totalPriceIP = this.leakTotalPriceIP
    instructions.leak.totalPriceImmediate = this.leakImmediate
    instructions.leak.totalPriceDeffered = this.leakDeffered

    instructions.relatedCost.totalPriceImmediate = this.relatedCostImmediate
    instructions.relatedCost.totalPriceDeffered = this.relatedCostDeffered
    instructions.relatedCost.trueTotal = this.totalRelatedCostsAnnexPriceWithVAT
    instructions.travelRCPPackage = { ...instructions.travelRCPPackage, ...this.travelRCPPackage }
    instructions.franchise.immediate = this.franchiseImmediate
    instructions.franchise.deffered = this.franchiseDeffered
    instructions.franchise.trueTotal = this.franchiseTotal
    instructions.totalInstructionsWithoutVAT = this.totalInstructionsWithoutVAT
    instructions.totalInstructionsWithVAT = this.totalInstructionsWithVAT

    instructions.asp.totalColumnTotal = this.totalColumnTotal
    instructions.asp.totalAsp = this.totalAsp
    instructions.asp.totalAspGarantieQuoteInvoice = this.totalAspGarantieQuoteInvoice
    instructions.asp.totalAspGarantieByEstimation = this.totalAspGarantieByEstimation
    instructions.asp.totalAspGarantie = this.totalAspGarantie

    return instructions
  }

  get compensatoryPackages() {
    let immediate = 0
    let deffered = 0

    const hasMixte = this.hasRen + this.hasPecunary + this.hasGag > 1
    if (hasMixte || this.asp) {
      return {
        immediate,
        deffered,
      }
    }

    const compensatoryConfigGag = getCompensatory('selfRepair', this.configuration)
    const compensatoryConfigRen = getCompensatory('ren', this.configuration)
    const compensatoryConfigPecunary = getCompensatory(
      'propertyAndEmbellishment',
      this.configuration,
    )

    const compensationMinPriceTotal =
      this.compensationMinPrice.immediate + this.compensationMinPrice.deffered

    if (this.hasGag && this.selfRepairTotal < compensatoryConfigGag.min) {
      immediate = compensatoryConfigGag.min - this.selfRepairTotal
    }

    const renTotalWithCompensation = this.renTotal + compensationMinPriceTotal
    if (this.hasRen && renTotalWithCompensation < compensatoryConfigRen.min) {
      deffered = compensatoryConfigRen.min - renTotalWithCompensation
    }

    const ipTotal = this.propertyAndEmbellishmentImmediate + this.propertyAndEmbellishmentDeffered
    const ipTotalWithCompensation = ipTotal + compensationMinPriceTotal
    if (this.hasPecunary && ipTotalWithCompensation < compensatoryConfigPecunary.min) {
      let ipCompensatory = compensatoryConfigPecunary.min - ipTotalWithCompensation
      immediate = roundDecimal(ipCompensatory / this.dividend)
      deffered = roundDecimal(ipCompensatory / this.dividend)
    }

    return {
      immediate,
      deffered,
    }
  }

  save = (type = 'cart', alertSuccess = false) => {
    if (this.debouncing) {
      clearTimeout(this.debouncing)
      this.debouncing = null
    }
    this.saving = true
    this.instructionsSaving = true
    const messageFail =
      type === 'cart' ? 'mission.calculation.cart.saveFail' : 'mission.rates.saveFail'

    let id = null
    if (UserStore.isExpert && !isNil(MissionStore.id)) {
      id = MissionStore.id
    } else if (UserStore.isClaimManager && !isNil(ManagerClaimStore.id)) {
      id = ManagerClaimStore.id
    }

    return saveCart({
      wan: id,
      cart: this.cartAsJson,
      totals: this.totalsFromCart,
      settlements: this.exposeSettlements,
      precisions: this.exposePrecisions,
      instructions: this.instructionsAsjson,
      ventilatedByInvolvedParties: {
        packages: this.exposeIPPackages,
        furnitures: this.exposeIPFurnitures,
      },
      idCart: this.id,
      easyEstimation: this.easyEstimation,
    })
      .then(
        action(lastUpdatedAt => {
          this.setOriginalData('originalSettlements', 'exposeSettlements')
          this.setOriginalData('originalPrecisions', 'exposePrecisions')
          this.originalInstructions = toJS(this.instructions, { recurseEverything: true })
          this.instructionsSaving = false
          this.lastUpdatedAt = lastUpdatedAt
          this.saving = false
          if (alertSuccess === true) {
            AlertCtrl.alert('success', 'common.saveSuccess')
          }
          if (UserStore.isExpert && !isNil(MissionStore.id) && MissionStore.appointmentDateRule) {
            MissionStore.updateStatus('CAAD', 'CAAF')
          }
          this.blockNavigation = false
          if (UserStore.isClaimManager) {
            ManagerClaimStore.reloadStatus()
          }
          if (UserStore.isExpert) {
            PvCtrl.clearEstimationOverride()
          }
        }),
      )
      .catch(
        action(err => {
          console.warn(err)
          AlertCtrl.alert('danger', messageFail)
          this.saving = false
          this.instructionsSaving = false
          this.blockNavigation = false
        }),
      )
  }

  fetch(id, data) {
    this.loading = true
    // FIX first init franchiseMustBeDeducted is null and Conclusion Dynform is NON_RECUPERABLE => trigger auto save in settlements
    // console.log(
    //   'setting cartstore : ',
    //   path(['estimation', 'calculation', 'conclusion', 'isFranchiseMustBeDeducted'], data),
    //   path(['estimation', 'calculation', 'conclusion', 'franchiseMustBeDeducted', 'id'], data),
    //   path(['estimation', 'calculation', 'conclusion', 'franchiseMustNotBeDeducted', 'id'], data),
    // )
    const isAffectedByOtherThirdParty =
      path(['riskConformity', 'affectedByExpertise', 'key'], data) === 'OTHER_THIRD_PARTY'
    const isFranchiseMustBeDeducted = path(
      ['estimation', 'calculation', 'conclusion', 'isFranchiseMustBeDeducted'],
      data,
    )

    if (isAffectedByOtherThirdParty) {
      this.initIsFranchiseMustBeDeducted = false
      this.initFranchiseMustBeDeducted = null
      this.initFranchiseMustNotBeDeducted = null
    } else {
      this.initIsFranchiseMustBeDeducted = isNil(isFranchiseMustBeDeducted)
        ? false
        : isFranchiseMustBeDeducted
      this.initFranchiseMustBeDeducted =
        path(['estimation', 'calculation', 'conclusion', 'franchiseMustBeDeducted', 'id'], data) ||
        'NON_RECUPERABLE'
      this.initFranchiseMustNotBeDeducted =
        path(
          ['estimation', 'calculation', 'conclusion', 'franchiseMustNotBeDeducted', 'id'],
          data,
        ) || 'IRSI_APPLICABLE'
    }

    fetchCart(id)
      .then(
        action(res => {
          this.id = res.id
          this.easyEstimation = res.easyEstimation
          this.originalEasyEstimation = res.easyEstimation
          PrejudiceStore.initData(path(['calculation', 'optionsAndOtherPrejudices'], res))
          this.configuration = new Configuration(res.configuration)
          this.asp = res.asp
          this.originalAsp = res.asp
          this.lastUpdatedAt = res.lastUpdatedAt

          this.packages = res.calculation.packages.map(
            packageData =>
              new Package({
                data: packageData,
                fromList: 'cart',
                packageType: null,
                roomType: null,
                priceType: null,
              }),
          )
          PropertyEmbellishmentCtrl.createRoomFromCart(this.packages)
          this.rcp = validRCP(res.calculation.roomCoveringPackages, this.packages)
          this.furnitures = res.calculation.furnitures.map(furniture =>
            furniture.type === 'catalog'
              ? new FurnitureCatalog(furniture)
              : new Furniture(furniture),
          )
          FurnitureCtrl.createCategoryFromCart(this.furnitures)
          this.relatedCosts = res.calculation.relatedCosts.map(
            relatedCost => new RelatedCost(relatedCost),
          )
          this.overflows = res.calculation.overflows.map(overflow => new RelatedCost(overflow))
          this.noGuarantees = res.calculation.noGuarantees.map(
            noGuarantee => new RelatedCost(noGuarantee),
          )

          // --- SETTLEMENTS ---
          if (res.calculation.settlements) {
            this.setSettlementsOverride(res.calculation.settlements)
          } else {
            // RE INIT DATA SETTLEMENTS IF NEW ESTIMATION
            this.initSettlements()
          }

          // --- PRECISIONS ---
          if (res.calculation.precisions) {
            this.setPrecisions(res.calculation.precisions)
          } else {
            // RE INIT DATA PRECISIONS IF NEW ESTIMATION
            this.initPrecisions()
          }

          // --- INSTRUCTIONS ---
          if (res.calculation.instructions) {
            this.instructions = mergeDeepRight(
              toJS(this.instructions, { recurseEverything: true }),
              res.calculation.instructions,
            )
          } else {
            this.initInstructions()
          }
          if (isNil(this.instructions.franchise.init)) {
            this.instructions.franchise.init = ManagerClaimStore.deductibleGuarantee
          }
          // SET ORIGINAL DATA
          this.setOriginalData('originalSettlements', 'exposeSettlements')
          this.setOriginalData('originalPrecisions', 'exposePrecisions')

          this.originalPackagesLength = this.packages.length
          this.originalFurnituresLength = this.furnitures.length
          this.originalRelatedCostLength = this.relatedCosts.length
          this.originalOverflowsLength = this.overflows.length
          this.originalNoGuaranteesLength = this.noGuarantees.length

          // AUTO SAVE
          this.autoSaveHandler = autorun(() => {
            if (UserStore.isExpert && (isNil(MissionStore.id) || MissionStore.isMissionClosed)) {
              return
            } else if (
              UserStore.isClaimManager &&
              (isNil(ManagerClaimStore.id) || ManagerClaimStore.isClaimClosed)
            ) {
              return
            }

            if (this.easyEstimation !== this.originalEasyEstimation) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              this.setProperty('originalEasyEstimation', this.easyEstimation)
            }

            if (this.asp !== this.originalAsp) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              this.setProperty('originalAsp', this.asp)
            }

            let datas = null

            if (this.originalPackagesLength !== this.packages.length) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              datas = this.packages
              this.setProperty('originalPackagesLength', this.packages.length)
            } else if (this.originalFurnituresLength !== this.furnitures.length) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              datas = this.furnitures
              this.setProperty('originalFurnituresLength', this.furnitures.length)
            } else if (this.originalRelatedCostLength !== this.relatedCosts.length) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              datas = this.relatedCosts
              this.setProperty('originalRelatedCostLength', this.relatedCosts.length)
            } else if (this.originalOverflowsLength !== this.overflows.length) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              datas = this.overflows
              this.setProperty('originalOverflowsLength', this.overflows.length)
            } else if (this.originalNoGuaranteesLength !== this.noGuarantees.length) {
              this.debounce(this.save.bind(this, 'cart'), 3000)
              datas = this.noGuarantees
              this.setProperty('originalNoGuaranteesLength', this.noGuarantees.length)
            }

            if (datas) {
              datas.forEach(data => data.setOriginalData())
              return
            }

            const packages = this.packages.filter(packageData => packageData.changed)
            const furnitures = this.furnitures.filter(furniture => furniture.changed)
            const qualifications = this.configuration.qualifications.filter(rate => rate.changed)
            const relatedCosts = this.relatedCosts.filter(relatedCost => relatedCost.changed)
            const overflows = this.overflows.filter(overflow => overflow.changed)
            const noGuarantees = this.noGuarantees.filter(noGuarantee => noGuarantee.changed)

            if (packages.length > 0) {
              this.debounce(this.save.bind(this, 'cart', packages), 3000)
              datas = packages
            } else if (furnitures.length > 0) {
              this.debounce(this.save.bind(this, 'cart', furnitures), 3000)
              datas = furnitures
            } else if (qualifications.length > 0) {
              this.debounce(this.save.bind(this, 'configuration', qualifications), 3000)
              datas = qualifications
            } else if (relatedCosts.length > 0) {
              this.debounce(this.save.bind(this, 'cart', relatedCosts), 3000)
              datas = relatedCosts
            } else if (overflows.length > 0) {
              this.debounce(this.save.bind(this, 'cart', overflows), 3000)
              datas = overflows
            } else if (noGuarantees.length > 0) {
              this.debounce(this.save.bind(this, 'cart', noGuarantees), 3000)
              datas = noGuarantees
            }

            if (datas) datas.forEach(data => data.setOriginalData())
          })

          this.conclusionViewSimplified = this.checkConclusionSimplified
          this.originalInstructions = toJS(this.instructions, { recurseEverything: true })
          this.loading = false
        }),
      )
      .catch(
        action(err => {
          console.error(err)
          this.loading = false
        }),
      )

    getFavoritePackages()
      .then(
        action(data => {
          this.favoritePackages = data
        }),
      )
      .catch(
        action(err => {
          console.error(err)
        }),
      )
  }

  findDifficultyRate(difficulty) {
    if (difficulty === 1) return parseFloat(this.configuration.difficultyRate1)
    else if (difficulty === 2) return parseFloat(this.configuration.difficultyRate2)
    else if (difficulty === 3) return parseFloat(this.configuration.difficultyRate3)

    console.warn('Package difficulty is unknown : ', difficulty)
    return 1
  }

  findQualification(cde) {
    if (this.configuration.qualifications.length === 0) return null

    const qualification = this.configuration.qualifications.find(
      qualification => qualification.cde === cde,
    )

    if (!qualification) {
      console.warn(`Qualification not found with cde: ${cde}`)
      return null
    }

    return qualification
  }

  setSettlementsOverride(settlements) {
    list.forEach(type => {
      const overrideType = type + 'Override'
      this[overrideType].immediateSettlement = settlements[type].override.immediateSettlement
      this[overrideType].defferedSettlement = settlements[type].override.defferedSettlement
      this[overrideType].settlementPresented = settlements[type].override.settlementPresented
      this[overrideType].totalPresented = settlements[type].override.totalPresented
    })
    this.deductibleOverride.immediateSettlement =
      path(['deductible', 'override', 'immediateSettlement'], settlements) || null
    this.upfrontExpert = settlements.upfrontExpert
  }

  setPrecisions(precisions) {
    listDeposit.forEach(type => {
      this[type].deposit = precisions[type].deposit
      this[type].defferedInvoice = precisions[type].defferedInvoice
      this[type].defferedResort = precisions[type].defferedResort
    })

    this.works1.defferedInvoice = precisions.works1.defferedInvoice
    this.works1.defferedResort = precisions.works1.defferedResort || 0

    this.works2.deposit = precisions.works2.deposit
    this.works2.defferedResort = precisions.works2.defferedResort

    this.precisionsDeductible.deposit = precisions.deductible.deposit
    this.precisionsDeductible.defferedResort = precisions.deductible.defferedResort
  }

  initSettlements() {
    list.forEach(type => {
      const overrideType = type + 'Override'
      this[overrideType].immediateSettlement = null
      this[overrideType].defferedSettlement = null
      this[overrideType].settlementPresented = null
      this[overrideType].totalPresented = null
    })

    this.upfrontExpert.immediateSettlement = 0
    this.upfrontExpert.defferedSettlement = 0

    this.originalSettlements = null
  }

  initPrecisions() {
    listDeposit.forEach(type => {
      this[type].deposit = 0
      this[type].defferedInvoice = 0
      this[type].defferedResort = 0
    })

    this.works1.defferedInvoice = 0
    this.works1.defferedResort = 0

    this.works2.deposit = 0
    this.works2.defferedResort = 0

    this.precisionsDeductible.deposit = 0
    this.precisionsDeductible.defferedResort = 0

    this.originalPrecisions = null
  }

  setPackagesOriginalData() {
    if (this.changedREN) {
      this.packagesWithoutSelfRepair.forEach(packageData => {
        packageData.setOriginalData()
      })
    }
  }

  resetPackagesToOriginalREN() {
    if (this.changedREN) {
      this.packagesWithoutSelfRepair.forEach(packageData => {
        packageData.resetOriginalREN()
      })
    }
  }

  isInvolvedPartyVentilated(involvedPartyId) {
    let ventilated = false

    for (let i = 0; i < this.packages.length; i++) {
      if (this.packages[i].involvedParty === involvedPartyId) {
        ventilated = true
        break
      }
    }
    if (ventilated) return true

    for (let i = 0; i < this.furnitures.length; i++) {
      if (this.furnitures[i].involvedParty === involvedPartyId) {
        ventilated = true
        break
      }
    }
    if (ventilated) return true

    for (let i = 0; i < this.relatedCosts.length; i++) {
      if (this.relatedCosts[i].involvedParty === involvedPartyId) {
        ventilated = true
        break
      }
    }
    if (ventilated) return true

    for (let i = 0; i < this.noGuarantees.length; i++) {
      if (this.noGuarantees[i].involvedParty === involvedPartyId) {
        ventilated = true
        break
      }
    }
    if (ventilated) return true

    for (let i = 0; i < this.overflows.length; i++) {
      if (this.overflows[i].involvedParty === involvedPartyId) {
        ventilated = true
        break
      }
    }

    return ventilated
  }

  handleKonamiCode = e => {
    const now = new Date()
    if (now - this.lastTimeKeyDown > 1000) {
      this.lastTypeKeyCodes = []
    }

    this.lastTimeKeyDown = now
    const keyCode = e.keyCode
    this.lastTypeKeyCodes.push(keyCode)

    if (this.lastTypeKeyCodes.length >= 10) {
      // konami cheat code Up, Up, Down, Down, Left, Right, Left, Right, B, A
      const konami = this.lastTypeKeyCodes.slice(-10).join('-')
      if (konami === '38-38-40-40-37-39-37-39-66-65') {
        this.showSaveButton = true
      }
    }
  }

  get packagesWithOutLeakSearch() {
    return this.packages.filter(pk => pk.packageType !== 'DAT_LEAK')
  }

  get isAllSelfRepair() {
    if (UserStore.isExpert && this.packagesWithOutLeakSearch.length === 0) return false

    if (
      UserStore.isClaimManager &&
      this.packagesWithOutLeakSearch.length === 0 &&
      this.relatedCostsAnnex.length === 0
    )
      return false

    let isAllSelfRepair = true
    this.packagesWithOutLeakSearch.forEach(pk => {
      if (!pk.selfRepair) isAllSelfRepair = false
    })

    return isAllSelfRepair && this.isAllRelatedCostSelfRepair
  }

  get isAllRelatedCostSelfRepair() {
    if (!UserStore.isClaimManager) return true

    let isAllRcSelfRepair = true
    this.relatedCostsAnnex.forEach(rc => {
      if (!rc.selfRepair) isAllRcSelfRepair = false
    })

    return isAllRcSelfRepair
  }

  get isAllCataloguePrice() {
    if (UserStore.isExpert && this.packagesWithOutLeakSearch.length === 0) return false

    if (
      UserStore.isClaimManager &&
      this.packagesWithOutLeakSearch.length === 0 &&
      this.relatedCostsAnnex.length === 0
    )
      return false

    let isAllCataloguePrice = true
    this.packagesWithOutLeakSearch.forEach(pk => {
      if (pk.selfRepair) isAllCataloguePrice = false
    })

    return !this.asp && isAllCataloguePrice && this.isAllRelatedCostCataloguePrice
  }

  get isAllRelatedCostCataloguePrice() {
    if (!UserStore.isClaimManager) return true

    let isAllRcCatalogue = true
    this.relatedCostsAnnex.forEach(rc => {
      if (rc.selfRepair) isAllRcCatalogue = false
    })

    return isAllRcCatalogue
  }

  get isAllRen() {
    if (UserStore.isExpert && this.packages.length === 0) return false

    if (
      UserStore.isClaimManager &&
      this.packages.length === 0 &&
      this.relatedCostsAnnex.length === 0
    )
      return false

    let isAllRen = true
    this.packages.forEach(pk => {
      if (!pk.ren) isAllRen = false
    })

    return isAllRen && this.isAllRelatedCostREN
  }

  get isAllRelatedCostREN() {
    if (!UserStore.isClaimManager) return true

    let isAllRcRen = true
    this.relatedCostsAnnex.forEach(rc => {
      if (!rc.ren) isAllRcRen = false
    })

    return isAllRcRen
  }

  get hasRen() {
    if (this.asp) return false

    let hasRen = false
    this.packages.forEach(pk => {
      if (!pk.selfRepair && pk.ren) hasRen = true
    })

    return hasRen || this.hasRelatedCostREN
  }

  get hasRelatedCostREN() {
    if (!UserStore.isClaimManager) {
      return false
    }

    let hasRen = false
    this.relatedCostsAnnex.forEach(rc => {
      if (rc.ren) hasRen = true
    })

    return hasRen
  }

  get hasPecunary() {
    if (this.asp) return false

    let hasPecunary = false
    this.packages.forEach(pk => {
      if (!pk.selfRepair && !pk.ren) hasPecunary = true
    })

    return hasPecunary || this.hasRelatedCostPecunary
  }

  get hasRelatedCostPecunary() {
    if (!UserStore.isClaimManager) {
      return false
    }

    let hasPecunary = false
    this.relatedCostsAnnex.forEach(rc => {
      if (!rc.selfRepair && !rc.ren) hasPecunary = true
    })

    return hasPecunary
  }

  get hasGag() {
    if (this.asp) return false

    let hasGag = false
    this.packages.forEach(pk => {
      if (pk.selfRepair) hasGag = true
    })

    if (furnituresPriceWithoutVAT(this.furnitures) > 0) {
      hasGag = true
    }

    return hasGag || this.hasRelatedCostGag
  }

  get hasRelatedCostGag() {
    let hasGag = false

    if (!UserStore.isClaimManager) {
      if (relatedCostsPriceWithoutVAT(this.relatedCostPackages, 'relatedCost') > 0) {
        hasGag = true
      }
    } else {
      this.relatedCostsAnnex.forEach(rc => {
        if (rc.selfRepair) hasGag = true
      })
    }

    return hasGag
  }

  get hasRCPRenPackage() {
    let hasRCPRenPackage = false
    this.packages.forEach(pk => {
      if (pk.ren && pk.packageType !== 'DAT_LEAK' && !pk.selfRepair) hasRCPRenPackage = true
    })

    return hasRCPRenPackage
  }

  get hasRCPPecunaryPackage() {
    let hasRCPPecunaryPackage = false
    this.packages.forEach(pk => {
      if (!pk.ren && pk.packageType !== 'DAT_LEAK' && !pk.selfRepair) hasRCPPecunaryPackage = true
    })

    return hasRCPPecunaryPackage
  }

  get hasTravelRCPPackage() {
    return this.hasRCPRenPackage || this.hasRCPPecunaryPackage
  }

  get travelRCPPackage() {
    let { travelPackage = 80 } = this.configuration
    travelPackage = travelPackage ? parseFloat(travelPackage) : 0
    const immediateOvverride = this.instructions.travelRCPPackage.immediateOvverride
    const defferedOverride = this.instructions.travelRCPPackage.defferedOverride

    if (!this.hasTravelRCPPackage) {
      return {
        immediate: this.verifyValue(immediateOvverride, 0),
        deffered: this.verifyValue(defferedOverride, 0),
      }
    }

    if (this.asp) {
      return {
        immediate: this.isAspInvoice
          ? this.verifyValue(immediateOvverride, 0)
          : this.verifyValue(immediateOvverride, roundDecimal(travelPackage / this.dividend)),
        deffered: this.isAspInvoice
          ? this.verifyValue(defferedOverride, travelPackage)
          : this.verifyValue(defferedOverride, roundDecimal(travelPackage / this.dividend)),
      }
    }

    return {
      immediate: !this.hasRCPPecunaryPackage
        ? this.verifyValue(immediateOvverride, 0)
        : this.verifyValue(immediateOvverride, roundDecimal(travelPackage / this.dividend)),
      deffered: !this.hasRCPPecunaryPackage
        ? this.verifyValue(defferedOverride, travelPackage)
        : this.verifyValue(defferedOverride, roundDecimal(travelPackage / this.dividend)),
    }
  }

  get compensationMinPrice() {
    let compensationMinPrice = {
      immediate: 0,
      deffered: 0,
    }

    if (this.asp) return compensationMinPrice

    let hasRCPRenPackage = false
    let hasRCPPecunaryPackage = false
    let hasRCPGagPackage = false
    this.packages.forEach(pk => {
      if (pk.packageType !== 'DAT_LEAK' && pk.selfRepair) hasRCPGagPackage = true
      if (pk.ren && pk.packageType !== 'DAT_LEAK' && !pk.selfRepair) hasRCPRenPackage = true
      if (!pk.ren && pk.packageType !== 'DAT_LEAK' && !pk.selfRepair) hasRCPPecunaryPackage = true
    })

    // mixte mode indemnisation
    if (hasRCPRenPackage + hasRCPPecunaryPackage + hasRCPGagPackage > 1) return compensationMinPrice

    const minPrice =
      this.minPrice - this.travelRCPPackage.immediate - this.travelRCPPackage.deffered

    if (hasRCPPecunaryPackage && this.totalPropertyAndEmbellishment < minPrice) {
      const compensation = minPrice - this.totalPropertyAndEmbellishment

      compensationMinPrice.immediate = roundDecimal(compensation / this.dividend)
      compensationMinPrice.deffered = roundDecimal(compensation / this.dividend)
    }

    if (hasRCPRenPackage && this.totalPackagesWithRen < minPrice) {
      compensationMinPrice.deffered = roundDecimal(minPrice - this.totalPackagesWithRen)
    }

    return compensationMinPrice
  }

  get minPrice() {
    let minPrice = 0
    this.packages.forEach(pk => {
      if (pk.packageType !== 'DAT_LEAK' && !pk.selfRepair) {
        pk.items.forEach(item => {
          if (item.piActive && item.qualificationMinPrice > minPrice)
            minPrice = item.qualificationMinPrice
        })
      }
    })

    return minPrice
  }

  get franchiseImmediate() {
    let franchiseInit = this.instructions.franchise.init
    if (this.asp && this.isAspInvoice) {
      franchiseInit = 0
    }

    return Math.min(this.totalColumnImmediate, franchiseInit)
  }

  get franchiseDeffered() {
    let remainderFranchise = this.instructions.franchise.init - this.franchiseImmediate
    return Math.min(this.totalColumnDeffered, remainderFranchise)
  }

  get franchiseTotal() {
    return this.franchiseImmediate + this.franchiseDeffered
  }

  get totalInstructionsWithoutVAT() {
    return (
      [...this.propertyPackages, ...this.embellishmentPackages].reduce(
        (acc, data) => add(acc, data.finalPriceWithoutVAT),
        0,
      ) +
      furnituresPriceWithoutVAT(this.furnitures) +
      relatedCostsPriceWithoutVAT(this.relatedCostPackages, 'relatedCost') +
      calculTotalPackages({
        packages: this.leakPackages,
        key: 'finalPriceCatalogWithoutVAT',
        selfRepair: false,
      }) +
      this.travelRCPPackage.immediate +
      this.travelRCPPackage.deffered
    )
  }
  get totalInstructionsWithVAT() {
    return (
      [...this.propertyPackages, ...this.embellishmentPackages].reduce(
        (acc, data) => add(acc, data.finalPrice),
        0,
      ) +
      furnituresPriceWithVAT(this.furnitures) +
      relatedCostsPriceWithVAT(this.relatedCostPackages, 'relatedCost') +
      calculTotalPackages({
        packages: this.leakPackages,
        key: 'finalPrice',
        selfRepair: false,
      }) +
      this.travelRCPPackage.immediate +
      this.travelRCPPackage.deffered
    )
  }

  changeAllPackageTo = (key, value) => {
    // In all cases we don't change selfRepair property of leak search
    this.packages.forEach(pk => {
      if (key === 'selfRepair') {
        if (pk.packageType !== 'DAT_LEAK') {
          pk.setProperty('selfRepair', value)
        }

        if (value === true) {
          pk.setProperty('ren', false)
        }
      }

      if (key === 'ren') {
        pk.setProperty(key, value)
        if (value === true && pk.packageType !== 'DAT_LEAK') {
          pk.setProperty('selfRepair', false)
        }
      }
    })

    this.changeAllRelatedCostTo(key, value)
  }

  changeAllPackageForIRSI2 = () => {
    // In all cases we don't change selfRepair property of leak search
    this.packages.forEach(pk => {
      if (pk.involvedParty === MissionStore.insuredInformationId) {
        pk.setProperty('ren', true)
      } else if (pk.involvedParty !== MissionStore.insuredInformationId) {
        pk.setProperty('ren', false)
        pk.setProperty('companyName', '')
      }
    })
    if (this.packages.length > 0) {
      this.conclusionViewSimplified = false
    }
  }

  changeAllRelatedCostTo = (key, value) => {
    if (!UserStore.isClaimManager) {
      return
    }

    this.relatedCostsAnnex.forEach(rc => {
      rc.setProperty(key, value)

      if (key === 'selfRepair' && value === true) {
        rc.setProperty('ren', false)
      }

      if (key === 'ren') {
        rc.setProperty('selfRepair', false)
      }
    })
  }

  get isTaxSystemHT() {
    if (UserStore.isClaimManager) return false

    return MissionStore.taxSystem === 'HT'
  }

  get totalAspImmediate() {
    let price = roundDecimal(InstructionCtrl.aspAmount / this.dividend)

    if (this.asp && this.isAspInvoice) {
      price = 0
    }

    return price
  }

  get totalAspDeffered() {
    let price = roundDecimal(InstructionCtrl.aspAmount / this.dividend)
    if (this.asp && this.isAspInvoice) {
      price = InstructionCtrl.aspAmount
    }

    return price
  }

  get totalAsp() {
    return this.totalAspImmediate + this.totalAspDeffered
  }

  get totalAdvanceAspImmediate() {
    if (!this.asp || !InstructionCtrl.isAspAdvanceAlreadyPaid) {
      return 0
    }

    return !this.isAspInvoice ? InstructionCtrl.aspAdvanceAmount : 0
  }

  get totalAdvanceAspDeffered() {
    if (!this.asp || !InstructionCtrl.isAspAdvanceAlreadyPaid) {
      return 0
    }

    return this.isAspInvoice ? InstructionCtrl.aspAdvanceAmount : 0
  }

  get totalAdvanceAsp() {
    return this.totalAdvanceAspImmediate + this.totalAdvanceAspDeffered
  }

  get totalAspGarantieQuoteInvoice() {
    let immediate = 0
    let deffered = 0
    immediate = this.totalAspImmediate - this.totalAdvanceAspImmediate
    deffered = this.totalAspDeffered - this.totalAdvanceAspDeffered
    if (InstructionCtrl.isFranchiseMustBeDeducted) {
      immediate = Math.max(0, immediate - this.franchiseImmediate)
      deffered = Math.max(0, deffered - this.franchiseDeffered)
    }

    return { immediate, deffered }
  }

  get totalAspGarantieByEstimation() {
    let immediate = 0
    let deffered = 0
    immediate = this.totalColumnImmediate - this.totalAdvanceAspImmediate
    deffered = this.totalColumnDeffered - this.totalAdvanceAspDeffered
    if (InstructionCtrl.isFranchiseMustBeDeducted) {
      immediate = Math.max(0, immediate - this.franchiseImmediate)
      deffered = Math.max(0, deffered - this.franchiseDeffered)
    }

    return { immediate, deffered }
  }

  get totalAspGarantie() {
    const { maxDifferenceAsp = Infinity } = this.configuration
    if (
      this.totalAsp > this.totalColumnTotal &&
      maxDifferenceAsp < this.totalAsp - this.totalColumnTotal
    ) {
      return this.totalAspGarantieByEstimation
    } else {
      return this.totalAspGarantieQuoteInvoice
    }
  }

  get dividend() {
    const { instructionDividend = 2, aspDividend = 2 } = this.configuration

    return this.asp ? aspDividend : instructionDividend
  }

  get isAspInvoice() {
    if (!UserStore.isClaimManager) {
      return false
    }

    return InstructionCtrl.isAspInvoice
  }
}

const DecoratedCartStore = decorate(CartStore, {
  packages: observable,
  asp: observable,
  originalAsp: observable,
  furnitures: observable,
  configuration: observable,
  relatedCosts: observable,
  noGuarantees: observable,
  overflows: observable,
  // configuration: observable,
  lastUpdatedAt: observable,
  saving: observable,
  loading: observable,
  originalPackagesLength: observable,
  originalFurnituresLength: observable,
  originalRelatedCostLength: observable,
  originalOverflowsLength: observable,
  originalNoGuaranteesLength: observable,
  conclusionViewSimplified: observable,
  debouncing: observable,
  blockNavigation: observable,
  favoritePackages: observable,
  showSaveButton: observable,
  deductibleOverride: observable,

  debounce: action,
  changeAllWithoutSelfRepairPackages: action,
  addPackage: action.bound,
  replacePackage: action.bound,
  replaceRelatedCost: action.bound,
  addCustomPackage: action,
  removePackage: action.bound,
  addFurniture: action.bound,
  removeFurniture: action.bound,
  addRelatedCost: action.bound,
  removeRelatedCost: action.bound,
  setProperty: action.bound,
  save: action,
  fetch: action,
  setOriginalData: action.bound,
  findDifficultyRate: action.bound,
  handleKonamiCode: action.bound,

  propertyPackages: computed,
  embellishmentPackages: computed,
  leakPackages: computed,
  propertyAndembellishmentPackages: computed,
  packagesWithoutSelfRepair: computed,
  propertyPackagesByRoom: computed,
  embellishmentPackagesByRoom: computed,
  leakPackagesByRoom: computed,
  packagesWithoutOutdoor: computed,
  packagesWithOutLeakSearch: computed,
  isAllSelfRepair: computed,
  isAllRelatedCostSelfRepair: computed,
  isAllCataloguePrice: computed,
  isAllRelatedCostCataloguePrice: computed,
  isAllRen: computed,
  isAllRelatedCostREN: computed,

  overrideRoomsLabel: computed,
  propertyTotalPriceCatalog: computed,
  propertyTotalPriceSelfRepair: computed,
  embellishmentTotalPriceCatalog: computed,
  embellishmentTotalPriceSelfRepair: computed,
  leakTotalPriceCatalog: computed,
  totalPriceCatalog: computed,
  totalPriceSelfRepair: computed,
  totalPricePackagesCatalog: computed,
  totalPricePackagesSelfRepair: computed,
  totalPackages: computed,
  totalFurniturePrice: computed,
  totalFurniturePriceWithoutVAT: computed,
  totalOutdoorFurniturePrice: computed,
  totalCatalogOR: computed,
  totalSelfRepairOR: computed,
  totalOverflowWithVAT: computed,
  totalOverflowWithoutVAT: computed,
  totalNoGuaranteeWithVAT: computed,
  totalNoGuaranteeWithoutVAT: computed,
  totalCatalog: computed,
  totalSelfRepair: computed,
  totalCartPrice: computed,
  furnituresPerCategory: computed,
  cartAsJson: computed,
  totalsFromCart: computed,
  checkConclusionSimplified: computed,
  simplifiedRen: computed,
  simplifiedCompanyName: computed,
  hasBpuPackage: computed,
  isSelfRepairRateAdjustable: computed,
  packagesPriceListLabels: computed,
  relatedCostPackages: computed,
  overflowPackages: computed,
  noGuaranteePackages: computed,
  outdoorPackages: computed,
  outdoorPackagesWithRoom: computed,
  outdoorPackagesWithoutRoom: computed,
  outdoorPackagesByRoom: computed,
  regularFurnitures: computed,
  outdoorFurnitures: computed,
  totalOutdoorCatalogWithVAT: computed,
  totalOutdoorSelfRepairWithVAT: computed,
  totalOutdoorWithVAT: computed,
  totalOutdoorCatalogWithRenWithVAT: computed,

  // SETTLEMENTS
  upfrontExpert: observable,
  originalSettlements: observable,
  finalPriceHelper: computed,
  settlements: computed,
  totalSettlementColumn: computed,
  totalBaseSettlementColumn: computed,
  deductible: computed,
  totalSettlements: computed,
  exposeSettlements: computed,
  settlementsChanged: computed,
  computeWithoutVAT: computed,
  franchiseMustBeDeducted: computed,
  totalContractSettlement: computed,
  totalAspImmediate: computed,
  totalAspDeffered: computed,
  totalAsp: computed,
  totalAdvanceAspImmediate: computed,
  totalAdvanceAspDeffered: computed,
  totalAdvanceAsp: computed,
  totalAspGarantieQuoteInvoice: computed,
  totalAspGarantieByEstimation: computed,
  totalAspGarantie: computed,
  setSettlementsOverride: action,
  resetSettlements: action.bound,
  initSettlements: action,

  // PRECISIONS
  works1: observable,
  works2: observable,
  precisionsDeductible: observable,
  originalPrecisions: observable,
  totalPrecisionsRow: computed,
  totalPrecisionsColumn: computed,
  packagesWithRen: computed,
  packagesWithoutRen: computed,
  totalPackagesWithRen: computed,
  totalPackagesWithoutRen: computed,
  precisionsMaxTotals: computed,
  exposePrecisions: computed,
  precisionsChanged: computed,
  setPrecisions: action,
  resetPrecisions: action.bound,
  initPrecisions: action,
  changeAllPackageTo: action,
  changeAllRelatedCostTo: action,

  // DYNFORM
  workAmount: computed,
  totalRemainderForInsured: computed,

  // VENTILATION IP
  isVentilationIPAvailable: computed,
  uniqIPs: computed,
  packagesWithIP: computed,
  packagesWithoutIP: computed,
  showComputationWithoutIP: computed,
  isInsuredVentilated: computed,
  listIP: computed,
  packagesSortedByIP: computed,
  computationsByIP: computed,
  computationsWithoutIP: computed,
  exposeIPPackages: computed,
  // VENTILATION FURNITURES BY IP
  furnituresWithIP: computed,
  furnituresWithoutIP: computed,
  furnituresSortedByIP: computed,
  furnituresComputationsByIP: computed,
  furnituresCompututationsWithoutIP: computed,
  exposeIPFurnitures: computed,
  // FOR DISPLAY ONLY
  ventilationIPsData: computed,

  // NEW VENTILATION REN
  RENByIP: computed,
  changedREN: computed,
  setPackagesOriginalData: action.bound,
  resetPackagesToOriginalREN: action.bound,

  // RC ANNEX
  relatedCostsAnnex: computed,
  totalRelatedCostsAnnexPriceWithoutVAT: computed,
  totalRelatedCostSelRepair: computed,
  totalRelatedCostsAnnexPriceWithVAT: computed,

  // Supporting documents
  cartSD: computed,

  // Easy Estimation
  easyEstimation: observable,
  originalEasyEstimation: observable,
  rcp: observable,
  addRCP: action,
  deleteRCP: action,
  changeGroupIdPackages: action,
  emptyCart: action.bound,
  deleteByRoom: action,
  selfRepairRate: computed,
  isCartEmpty: computed,
  dividend: computed,

  // Instructions
  instructions: observable,
  originalInstructions: observable,
  instructionsSaving: observable,
  setInstructions: action,
  initInstructions: action,
  instructionsChanged: computed,
  updateInstructionsOrigined: action,
  resetInstructionsToOrigine: action,
  instructionsIRSIRule: action,
  instructionsPresentedRule: action,
  compensatoryPackages: computed,
  totalPropertyAndEmbellishment: computed,
  totalWithoutObsWithoutVAT: computed,
  totalCatalogORWithoutVAT: computed,
  propertyAndEmbellishmentRules: computed,
  selfRepairImmediate: computed,
  propertyAndEmbellishmentImmediate: computed,
  furnitureImmediate: computed,
  relatedCostImmediate: computed,
  relatedCostDeffered: computed,
  totalColumnImmediate: computed,
  renDeffered: computed,
  propertyAndEmbellishmentDeffered: computed,
  leakDeffered: computed,
  leakImmediate: computed,
  totalColumnDeffered: computed,
  selfRepairTotal: computed,
  renTotal: computed,
  leakTotal: computed,
  furnitureTotal: computed,
  relatedCostTotal: computed,
  totalColumnTotal: computed,
  totalColumnPresented: computed,
  hasTravelRCPPackage: computed,
  hasRCPRenPackage: computed,
  hasRCPPecunaryPackage: computed,
  travelRCPPackage: computed,
  hasRen: computed,
  hasRelatedCostREN: computed,
  hasPecunary: computed,
  hasRelatedCostPecunary: computed,
  hasGag: computed,
  hasRelatedCostGag: computed,
  franchiseImmediate: computed,
  franchiseDeffered: computed,
  franchiseTotal: computed,
  compensationMinPrice: computed,
  minPrice: computed,
  isTaxSystemHT: computed,
  isAspInvoice: computed,
})

export default new DecoratedCartStore()
