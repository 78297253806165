import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path } from 'ramda'
import i18next from 'i18next'

import UiCtrl from 'stores/Common/view/UiCtrl'
import Message from 'stores/BlocNote/Message'
import UserStore from 'stores/Common/domain/UserStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { fetchMessages, postMessage } from 'services/blocNote'

class BlocNoteStore {
  loading = false
  sendingMessage = false
  messages = []
  contexts = []
  currentMessage = new Message()
  currentContext = ''
  showSDModal = false
  loadingMessages = false
  wan = null
  notifications = 0

  get contextsAsOptions() {
    const { language } = UiCtrl

    return this.contexts.map(context => ({
      value: context.id,
      label: context[language[0] + language[1]],
    }))
  }

  get validMessage() {
    return !!this.currentMessage.text
  }

  get sendFromExpert() {
    return {
      ...this.currentMessage.asJson,
      context: 'sendToPole',
      wanSource: path(['cfa', 'wan'], MissionStore),
      wanDest: MissionStore.isCreatedFromCfm
        ? path(['cfa', 'cfmWan'], MissionStore)
        : path(['cfa', 'cfi', 'wan'], MissionStore),
      customerIdDest: path(['customer', 'id'], UserStore),
    }
  }

  async loadData(wan) {
    this.loading = true
    try {
      await Promise.all([this.fetchMessages(wan)])
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchMessages(wan) {
    this.loadingMessages = true
    this.wan = wan
    try {
      const res = await fetchMessages({ wan, filter: null })
      runInAction(() => {
        this.loadingMessages = false
        this.messages = res.data.map(data => new Message(data))
      })
    } catch (err) {
      console.error(err)
    }
  }

  sendMessage = async () => {
    this.sendingMessage = true
    try {
      const body = this.sendFromExpert
      if (body) {
        await postMessage(body)
        this.fetchMessages(this.wan)
        runInAction(() => (this.currentMessage = new Message()))
      } else {
        console.warn(`Something went wrong with text`)
        AlertCtrl.alert(
          'warning',
          i18next.t('messaging.form.contextError', { context: this.currentContext }),
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => (this.sendingMessage = false))
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  markAsReadNotification() {
    this.notifications = this.notifications - 1
  }

  closeModal() {
    this.showSDModal = !this.showSDModal
    this.currentMessage.setProperty('selectingDocuments', [])
  }

  confirmSelection() {
    this.showSDModal = !this.showSDModal
    this.currentMessage.setProperty('documents', this.currentMessage.selectingDocuments)
    this.currentMessage.setProperty('selectingDocuments', [])
  }

  resetCurrentMessage() {
    this.currentMessage = new Message()
    this.currentContext = ''
  }
}

const decoratedBlocNoteStore = decorate(BlocNoteStore, {
  loading: observable,
  sendingMessage: observable,
  loadingMessages: observable,
  messages: observable,
  contexts: observable,
  currentContext: observable,
  showSDModal: observable,
  wan: observable,
  notifications: observable,
  currentMessage: observable,

  contextsAsOptions: computed,
  validMessage: computed,
  sendFromExpert: computed,

  loadData: action,
  fetchMessages: action.bound,
  sendMessage: action.bound,
  setProperty: action.bound,
  closeModal: action.bound,
  confirmSelection: action.bound,
  resetCurrentMessage: action.bound,
  markAsReadNotification: action,
})

export default new decoratedBlocNoteStore()
